import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../../widget/Toast_noty'
import Loader from "../../../../widget/Loader/Loader";
import {Add_Album_three , Get_All_Artists} from '../../../../widget/API';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {Redirect} from 'react-router-dom';
import AlbumAddStepThreeListTrack from './AlbumAddStepThreeListTrack'
import {Get_Track} from '../../../../widget/API';
import { Select , Space, Form , Input ,InputNumber} from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import TrackAutoFields from '../../../track/TrackAutoFields';
import moment from "moment";




function AlbumAddStepThree(props) {
    const[listTrack , setListTrack]=useState([]);
    const[dispaly , setDisplay]=useState(false);
    const[uploadSuccess , setuploadSuccess]=useState(false);
    const[typeAlbum , setTypeAlbum]=useState(null);
    const[fileMusic , setFileMusic]=useState(null);
    const[name , setName]=useState(null);
    const [duration , setDuration]=useState(0);
    const[feats , setFeats]=useState(false);
    const[listArtists , setListArtists]=useState([]);
    const { Option } = Select;



    const selectFile= async(e)=>{
        await setFileMusic(e.target.files[0])
        const audio = await document.getElementsByTagName('audio')[0] ;
        audio.onloadedmetadata = ()=>{
            setDuration(parseInt(audio.duration));
        }
    }


    const getTrackList=()=>{
        const returndata =  Get_Track(props.albumId);
        returndata.then((res)=>{
        if(res.result == 'ok'){
            setListTrack(res.data);
        }
        })
    }

    const getArtists=()=>{
        setDisplay(true);
        const returndata =  Get_All_Artists();
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setListArtists(res.data)
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }


    const onFinish=(values)=>{
        if(values && values.labels){
            values['labels'] =  Object.entries(values.labels).map(([key , value])=>{
                if(value && value._d){
                    return {
                        "id" : key,
                        "value" : String(moment(value._d).format('YYYY-MM-DD hh:mm')),
                    }
                }
                else {
                    return {
                        "id" : key,
                        "value" : String(value),
                    }
                }
                
            })
        }
        setDisplay(true);
        const returndata =  Add_Album_three(props.albumId ,values ,props.albumPay , typeAlbum , fileMusic , duration);
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            setuploadSuccess(true)
            if(typeAlbum == 'album'){
                setFileMusic(null);
                setName(null);
                setDuration(0)
                document.getElementById('addalbum-cont-form').reset()
                Toast_noty('Add track success', 5000, "success");
                getTrackList()
            }
            else if(typeAlbum == 'single') {
                setFileMusic(null);
                setName(null);
                setDuration(0)
                document.getElementById('addalbum-cont-form').reset()
                props.setStep(1)
                Toast_noty('Add track success', 5000, "success");
            }
        }
        else 
        {
            Toast_noty(res.error, 5000, "error");
        }
        })
        returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }


    useEffect(()=>{
        getArtists()
    },[])


    return (
        <>
            <div className="addalbum-cont">
                {dispaly && <Loader/>}
                {/* <div className="addalbum-cont-head">
                    <h3>Add Track</h3>
                </div> */}
                <Form id="addalbum-cont-form" onFinish={onFinish} className="addalbum-cont-form">
                    {!uploadSuccess && 
                        <div className="addalbum-cont-form-type">
                            <span>Please select type of album</span>
                            <div className="addalbum-cont-form-type-sec">
                                <div onClick={()=>{setTypeAlbum('single')}} className={`addalbum-cont-form-type-sec-item ${typeAlbum == 'single' && 'addalbum-cont-form-type-sec-item-active'}`}>Single</div>
                                <div onClick={()=>{setTypeAlbum('album')}} className={`addalbum-cont-form-type-sec-item ${typeAlbum == 'album' && 'addalbum-cont-form-type-sec-item-active'}`}>Album</div>
                            </div>
                        </div>
                    }
                    {typeAlbum != null && 
                    <>
                        <div className="addalbum-cont-form-section">

                            {typeAlbum == 'album' && 
                                <div className="addalbum-cont-form-section-item">
                                    <span>Track name</span>
                                    <Form.Item onChange={(e)=>{setName(e.target.value)}} name="name" rules={[{ required: true}]}>
                                        <Input />
                                    </Form.Item>
                                </div>
                            }

                            <div className="addalbum-cont-form-section-item">
                                <span>Lyric</span>
                                <Form.Item  name="lyric" rules={[{ required: true}]}>
                                    <Input />
                                </Form.Item>
                            </div>

                            <div className="addalbum-cont-form-section-item">
                                <span>Producer</span>
                                <Form.Item  name="producer" rules={[{ required: true}]}>
                                    <Input />
                                </Form.Item>
                            </div>

                            <TrackAutoFields/>

                            <div className="addalbum-cont-form-section-item">
                                <span>Music producer</span>
                                <Form.Item  name="music_producer" rules={[{ required: true}]}>
                                    <Input />
                                </Form.Item>
                            </div>

                            {/* {typeAlbum == 'album' && props.albumPay &&
                                <div className="addalbum-cont-form-section-item">
                                    <span>Rial price</span>
                                    <Form.Item name="Rial_price" rules={[{ required: true}]}>
                                        <InputNumber />
                                    </Form.Item>
                                </div>
                            } */}

                            {/* {typeAlbum == 'album' && props.albumPay &&
                                <div className="addalbum-cont-form-section-item">
                                     <span>Dollar price</span>
                                        <Form.Item name="Dollar_price" rules={[{ required: true}]}>
                                            <InputNumber />
                                        </Form.Item>
                                </div>
                            } */}

                            {/* {typeAlbum == 'album' && props.albumPay && 
                                <div className="addalbum-cont-form-section-item">
                                    <span>Vip</span>
                                    <Form.Item name="vip" rules={[{ required: true}]}>
                                        <Select
                                            allowClear
                                            >
                                                <Option value={true}>Yes</Option>
                                                <Option value={false}>No</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            } */}

                            <div className="addalbum-cont-form-section-item">
                                <span>Feats</span>
                                <Form.Item name="isFeats" >
                                    <Select
                                        allowClear
                                        onChange={(value)=>{setFeats(value)}}
                                        >
                                            <Option value={true}>Yes</Option>
                                            <Option value={false}>No</Option>
                                    </Select>
                                </Form.Item>
                            </div>

                            {feats && 
                                <div className="addalbum-cont-form-section-feat">
                                    <Form.List name="feats">
                                        {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                <Form.Item
                                                {...restField}
                                                name={[name, 'artist_id']}
                                                fieldKey={[fieldKey, 'artist_id']}
                                                rules={[{ required: true, }]}
                                                >
                                                    <Select
                                                        placeholder="Select artist"
                                                        allowClear
                                                        >
                                                            {listArtists.map(item=>
                                                                <Option value={item.id}>{item.name}</Option>
                                                            )}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                {...restField}
                                                name={[name, 'percentage']}
                                                fieldKey={[fieldKey, 'percentage']}
                                                rules={[{ required: true}]}
                                                >
                                                <InputNumber placeholder="percentage" />
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </Space>
                                            ))}
                                            <Form.Item>
                                            <div className="addalbum-cont-form-section-feat-add-btn"  onClick={() => add()}>
                                                Add feats
                                            </div>
                                            </Form.Item>
                                        </>
                                        )}
                                    </Form.List>
                                </div>
                            }

                            <div className="addalbum-cont-form-section-item-file">
                                {fileMusic == null && 
                                    <div className="addalbum-cont-form-section-item-file-inner">
                                        <label>
                                            <span>+ Select music file</span>
                                            <input accept=".mp3" onChange={(e)=>selectFile(e)} type="file" required />
                                        </label>
                                    </div>
                                }
                                {fileMusic != null &&
                                    <div className="addalbum-cont-form-section-item-file-player">
                                        <div className="addalbum-cont-form-section-item-file-player-cover">
                                            <img src={URL.createObjectURL(props.coverFile[0].originFileObj)} alt="cover" />
                                            <span>{name}</span>
                                        </div>
                                        <div className="addalbum-cont-form-section-item-file-player-main">
                                            <AudioPlayer
                                                autoPlay
                                                src={URL.createObjectURL(fileMusic)}
                                            // other props here
                                            />
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                        {fileMusic != null &&
                            <div className="addalbum-cont-form-footer">
                                <button className="addalbum-cont-form-footer-btn-next">Upload Track</button>
                                <button type="button" onClick={()=>{setFileMusic(null)}} className="addalbum-cont-form-footer-btn-del">Delete</button>
                            </div>
                        }
                    </>
                    }
                </Form>
            </div>
            {listTrack.length > 0 && 
                <AlbumAddStepThreeListTrack listTrack={listTrack} />
            }
        </>    
    )
}

export default AlbumAddStepThree;