import { BrowserRouter as Router, Route } from 'react-router-dom';
import Base from './widget/Base/Base'
import './static/css/style.css';
import './static/css/antd.css';
import Login from './auth/Login';
import LoginFromAdmin from './auth/LoginFromAdmin';
import Register from './auth/Register';
import Verify from './auth/Verify';
import Forget from './auth/Forget';
import VerifyForget from './auth/VerifyForget';
import PasswordReset from './auth/PasswordReset';
import PrivateRoute from './widget/PrivateRoute'
import { useEffect } from 'react';


function App() {


  return (
    <Router>
      <Route exact path="/" component={Login} />
      <Route exact path="/al" component={LoginFromAdmin} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/verify" component={Verify} />
      <Route exact path="/forget" component={Forget} />
      <Route exact path="/verifyforget" component={VerifyForget} />
      <Route exact path="/passwordreset" component={PasswordReset} />

      <PrivateRoute path="/panel" component={Base} />
    </Router>
  );
}

export default App;
