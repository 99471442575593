import {ToastContainer} from "react-toastify";
import { useState } from "react";
import Toast_noty from '../../../../widget/Toast_noty'
import Loader from "../../../../widget/Loader/Loader";
import {Add_Album_three} from '../../../../widget/API';
import Table from "../../../../widget/Table/Table";
import {Link} from 'react-router-dom';



function AlbumAddStepThreeListTrack(props) {
    const[dispaly , setDisplay]=useState(false);


    const col = [
        {
            title:"id" ,
            sort:'id' ,
            render:(i) => (<>{i.id != null && i.id}</>)
        },
        {
            title:"Name" ,
            sort:'name' ,
            render:(i) => (
                <Link to={`/panel/user/${i.id}`}>
                    <div className="table-profile">
                        <div>{i.name != null && i.name.substr(0, 1)}</div>
                        <span>{i.name != null && i.name}</span>
                    </div>
                </Link>
                )
        },
    ]


    return (
        <div className="fullPage">
            <div className="pagelist">
                <Table range={10} columns={col} data={props.listTrack} />
            </div>
        </div>    
    )
}

export default AlbumAddStepThreeListTrack;