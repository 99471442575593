import React, { useState } from "react" ;
import './loaderStyle.css';


function Loader(props) {



    return (
        <div className="loader">
            <div  id="loader-6">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            </div>
        </div>
    )
}

export default Loader;