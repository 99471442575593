import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../widget/Toast_noty'
import Loader from "../../widget/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import {Get_Auto_Feilds , Get_Table_Value} from '../../widget/API';
import { Select , DatePicker, Form , Input ,InputNumber} from 'antd';




function AlbumAutoFields(props) {
    const[dispaly , setDisplay]=useState(false);
    const[listData , setListData]=useState([])
    const[listDataTable , setListDataTable]=useState({})
    const { Option } = Select;



    const getTableList=(data)=>{
        let dataTable = {...listDataTable}
        for(let i of data ){
                if(i.property && i.property.type == 'enum' && i.property.table == 1 && !listDataTable[i.property.values.substring(11)]){
                    const returndata =  Get_Table_Value(i.property.values.substring(11));
                    returndata.then((res)=>{
                    if(res.result == 'ok'){
                        dataTable[i.property.values.substring(11)] = res.data ;
                        setListDataTable(dataTable)
                    }
                    })
                }
        }
    }



    const getData=()=>{
        const returndata =  Get_Auto_Feilds('Album');
        returndata.then((res)=>{
        if(res.result == 'ok'){
            setListData(res.data)
            getTableList(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
    }
 
    useEffect(()=>{
        getData();
    },[listDataTable])


    return (
            <>
                {dispaly && <Loader/>}
                {listData.map(item=>
                <>

                    {item.property.type == 'number' && 
                        <div className="addalbum-cont-form-section-item">
                            <span>{item.property.name}</span>
                            <Form.Item name={['labels' ,String(item.id)]} rules={[{ required: item.requirement}]}>
                                <InputNumber />
                            </Form.Item>
                        </div>
                    }

                    {item.property.type == 'string' && 
                        <div className="addalbum-cont-form-section-item">
                            <span>{item.property.name}</span>
                            <Form.Item name={['labels' ,String(item.id)]} rules={[{ required: item.requirement}]} >
                                <Input />
                            </Form.Item>
                        </div>
                    }

                    {item.property.type == 'date_time' && 
                        <div className="addalbum-cont-form-section-item">
                            <span>{item.property.name}</span>
                            <Form.Item name={['labels' ,String(item.id)]} rules={[{ required: item.requirement}]} >
                                <DatePicker  />
                            </Form.Item>
                        </div>
                    }

                    {item.property.type == 'enum' && 
                        <div className="addalbum-cont-form-section-item">
                            <span>{item.property.name}</span>
                            <Form.Item name={['labels' ,String(item.id)]} >
                                <Select
                                    mode={item.property.choose == "multi" && "multiple"}
                                    allowClear
                                    rules={[{ required: item.requirement}]}
                                    >
                                        {item.property.table == 0 && 
                                            <>
                                                {JSON.parse(item.property.values).map(itemValue=>
                                                    <Option value={itemValue}>{itemValue}</Option>
                                                )}
                                            </>    
                                        }

                                        {item.property.table == 1 && 
                                            <>
                                                {listDataTable[item.property.values.substring(11)] && listDataTable[item.property.values.substring(11)].map(itemsList=>
                                                    <Option value={itemsList.id}>{itemsList.name}</Option>
                                                )}
                                            </>
                                        }
                                </Select>
                            </Form.Item>
                        </div>
                    }

                </>

                )}
            </>            
    )
}

export default AlbumAutoFields;