import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../widget/Toast_noty'
import Profile from '../../../static/img/profile.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faLock } from "@fortawesome/free-solid-svg-icons";
import {Add_User , Get_Roles} from '../../../widget/API';
import Loader from "../../../widget/Loader/Loader";
import '../userStyle.css';
import {Select} from 'antd'


function UserAdd(props) {
    const[dispaly , setDisplay]=useState(false);
    const[listRoles , setlistRoles]=useState([]);
    const[selectRole , setSelectRole]=useState(null)
    const{Option} = Select ;
 

    const Adduser=(e)=>{
        e.preventDefault();
        if(e.target[2].value.length > 7){
            setDisplay(true);
            const returndata =  Add_User(e , selectRole);
            returndata.then((res)=>{
            setDisplay(false);
            if(res.result == 'ok'){
                props.history.push('/panel/userlist');  
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
            returndata.catch((er)=>{
                setDisplay(false);
                Toast_noty('please try again', 5000, "error");
            })
        }
        else {
            Toast_noty('Password must be longer than 8 characters', 5000, "error");
        }    
    }


    useEffect(()=>{
        const returndata =  Get_Roles();
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setlistRoles(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    },[])

    

    return (
        <div className={`fullPage`}>
          {dispaly && <Loader/>}
          <div className="useradd">
              {/* <div className="useradd-head">
                  <img src={Profile} alt="profile" />
                  <div>
                      <h3>Selina Kyle </h3>
                  </div>
              </div> */}
              <form onSubmit={Adduser} className="useradd-form">

                <div className="useradd-form-one">
                    <div className="useradd-form-one-item">
                        <span>Email</span>
                        <input type="email" required/>
                    </div>
                    <div className="useradd-form-one-item">
                        <span>Name</span>
                        <input type="text" required/>
                    </div>
                    <div className="useradd-form-one-item">
                        <span>Password</span>
                        <input type="password" required/>
                    </div>
                    
                    {/* <div className="useradd-form-one-item">
                        <span>Role</span>
                        <select required>
                            <option value="">--</option>
                            {listRoles.map(item=>
                                <option value={item.id}>{item.name}</option>
                            )}
                        </select>
                    </div> */}

                    <div className="useradd-form-one-item">
                        <span>Role</span>
                        <Select
                            onChange={(value)=>{setSelectRole(value)}}
                            >
                            {listRoles.map(item=>
                                <Option value={item.id}>{item.name}</Option>
                            )}
                        </Select>
                    </div>

                    <div className="useradd-form-one-item">
                        <span>Phone</span>
                        <input type="tel" required/>
                    </div>
                </div>  

                <div className="useradd-form-bottom">
                    <button>Add User</button>
                </div>
                
              </form>
          </div>

           <ToastContainer position="top-right"
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}

export default UserAdd;