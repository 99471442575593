import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../../widget/Toast_noty'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFileImage, faHeart, faMailBulk, faMoneyBill, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import FileMusicVideo from '../../../../widget/FileMusicVideo'
import CModal from "../../../../widget/CModal/CModal";
import Loader from '../../../../widget/Loader/Loader';
import {Update_Music_Video} from '../../../../widget/API'
import checkAccess from "../../../../widget/checkAccess";
import {BASE_Medai_Video} from '../../../../widget/BASE_Medai_Video';


function MusicVideoDetailCont(props) {
    const [selectFileForUpdateMusicVideo , setSelectFileForUpdateMusicVideo]=useState(null)
    const[visibleUpdateVideo , setVisibleUpdateVideo]=useState(false);
    const[dispaly , setDisplay]=useState(false);


    const onFinishUpdateMusicVideo=()=>{
        setDisplay(true)
        const returndata =  Update_Music_Video(props.musicVideo.id , selectFileForUpdateMusicVideo);
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            props.getDetailMusiVideo();
            setVisibleUpdateVideo(false);
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
            returndata.catch((er)=>{
            setDisplay(false)
            Toast_noty('please try again', 5000, "error");
        })
    }


    const emptyFields=()=>{
        setSelectFileForUpdateMusicVideo(null)
    }

    return (
        <>
            
            <CModal onScap={emptyFields} visible={visibleUpdateVideo} setVisible={setVisibleUpdateVideo} title="Update video"> 
                <div className="musicvideodetail-update-modal">
                    <label>
                        <input accept="video/*"  onChange={(e)=>setSelectFileForUpdateMusicVideo(e.target.files[0])} type="file" required />
                        {selectFileForUpdateMusicVideo == null ? 
                            <span>+ Select music video file</span>
                        :
                            <span>{selectFileForUpdateMusicVideo.name}</span>
                        }
                    </label>
                    {selectFileForUpdateMusicVideo != null && 
                        <button className="musicvideo-modal-player-btn" onClick={onFinishUpdateMusicVideo} >Update</button>
                    }
                </div>
            </CModal>
            {dispaly && <Loader />}
            <div className="fullPage">   
            {props.musicVideo != null && 
            <div className="pagelist">
                <div className="pagelist-head">
                    <span>Video</span>
                </div>
                <div className="musicvideodetail-prev-video">
                    {/* <FileMusicVideo musicVideoId={props.musicVideo.id} /> */}
                    <video src={`${BASE_Medai_Video}${props.musicVideo.url}`} controls/>
                    {/* <video src={`http://vps.downloadforever.ir:8000/api/test`} autoPlay controls/> */}

                    <div className="addmusicvideo-cont-form-footer">
                        {checkAccess(19) && 
                            <button onClick={()=>{setVisibleUpdateVideo(true)}} className="addmusicvideo-cont-form-footer-btn-next">Change video</button>
                        }
                    </div>
                </div>
            </div>
            }
            </div>  
        </>
    )
}

export default MusicVideoDetailCont;