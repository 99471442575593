import React, { useState } from "react" ;
import './skeletonStyle.css';


function SkeletonAlbum(props) {



    return (
        <>
        <div className="skeleton-album"><div className="skeleton-album-inner"></div></div>
        <div className="skeleton-album"><div className="skeleton-album-inner"></div></div>
        <div className="skeleton-album"><div className="skeleton-album-inner"></div></div>
        <div className="skeleton-album"><div className="skeleton-album-inner"></div></div>
        </>
    )
}

export default SkeletonAlbum;