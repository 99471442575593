import Toast_noty from '../widget/Toast_noty';


export default function checkAccess(id) {
    const user = JSON.parse(localStorage.getItem('userInfo'))
    if (user)
        if (user.role_id == -1 || user.permissions.includes(id))
            return true
    else {
        return false
    }

}