import React, { useEffect, useState } from "react" ;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft ,faArrowsAlt, faEllipsisV , faTrash , faEdit, faPlay, faTruckMoving , faUser, faMinus} from "@fortawesome/free-solid-svg-icons";
import TablePage from "../../../../widget/Table/TablePage";
import Table from "../../../../widget/Table/Table";
import { Link } from "react-router-dom";
import Toast_noty from '../../../../widget/Toast_noty'
import CModal from "../../../../widget/CModal/CModal";
import {
    Play_Track ,
    Update_Track_Audio ,
    Delete_Track ,
    Get_All_Artists ,
    Edit_Track_Fits ,
    Get_All_Album_List ,
    Move_Track_To_Album
} from '../../../../widget/API';
import AudioPlayer from 'react-h5-audio-player'
import Loader from "../../../../widget/Loader/Loader";
import {Form , Select , Space,Image , InputNumber} from 'antd'
import Placeholder from '../../../../static/img/placeholder.png'
import {BASE_Medai_URL} from '../../../../widget/BASE_Medai_URL'
import checkAccess from "../../../../widget/checkAccess";



function AlbumDetailTrack(props) {
    const [display , setDisplay]=useState(false);

    const [visibleFits , setVisibleFits]=useState(false);
    const [listFits , setListFits]=useState([]);

    const [visibleDelete , setVisibleDelete]=useState(false);
    const [deleteId , setDeleteId]=useState(false);

    const [visibleMove , setVisibleMove]=useState(false);
    const [moveId , setMoveId]=useState(false);
    const [listAlbum , setListAlbum]=useState([]);



    const [visibleEditFits , setVisibleEditFits]=useState(false);
    const [trackFitsEdit , setTrackFitsEdit]=useState(null);
    const [listArtists , setListArtists]=useState([])

    const [visiblePlay , setVisiblePlay]=useState(false);
    const [visibleUpdateTrack, setVisibleUpdateTrack]=useState(false);
    const [trackUpdateName, setTrackUpdateName]=useState(null);
    const[listTrack , setListTrack]=useState([])
    const[actionList , setActionList]=useState(-1);
    const [audioFile , setAudioFile]=useState('');

    const[trackId , setTrackId]=useState(null);

    const[fileMusicUpdate , setFileMusicUpdate]=useState(null);
    const [durationUpdate , setDurationUpdate]=useState(0);


    const { Option } = Select;


    const openMoveModal=(id)=>{
        setDisplay(true);
        const returndata =  Get_All_Album_List();
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setListAlbum(res.data)
            setVisibleMove(true)
            setMoveId(id)
        }
        })
    }

    const moveTrackFinish=(values)=>{
        setDisplay(true);
        const returndata =  Move_Track_To_Album(props.album.id ,moveId ,  values.albums );
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            props.getDetailTrack();
            setVisibleMove(false)
            Toast_noty("Success", 5000, "success");
        }
        })
    }

    const changeDisplayActionList=(id)=>{
        if(actionList == id){
            setActionList(-1)
        }
        else {
            setActionList(id)
        }
    }

    const openFitsModal=(fits)=>{
        setVisibleFits(true)
        setListFits(fits)
    }

    const openModalEditFits=(id)=>{
        setTrackFitsEdit(id);
        setDisplay(true);
        const returndata =  Get_All_Artists();
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setListArtists(res.data)
            setVisibleEditFits(true)
        }
        })
    }

    const onfinishEditFits=(values)=>{
        if(values.feats || values.feats.length > 0){
            setDisplay(true)
            const returndata =  Edit_Track_Fits(values , props.album.id , trackFitsEdit);
            returndata.then((res)=>{
            setDisplay(false);
            if(res.result == 'ok'){
                setTrackFitsEdit(null)
                setVisibleEditFits(false)
                props.getDetailTrack();    
            }
            })
        }
        else {
            Toast_noty("List empty", 5000, "error");
        }
    }


    const openDeleteModal=(id)=>{
        setVisibleDelete(true)
        setDeleteId(id)
    }


    const onFinishDelete=()=>{
        const returndata =  Delete_Track(props.album.id , deleteId );
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setVisibleDelete(false)
            emptyFields()
            props.getDetailTrack();
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
            returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
    }


    const openUpdateTrackModal=(track)=>{
        setTrackId(track.id)
        setTrackUpdateName(track.name);
        setVisibleUpdateTrack(true)
        setFileMusicUpdate(null)
        setDurationUpdate(0)
    }

    const selectFileForUpdateTrack= async(e)=>{
        await setFileMusicUpdate(e.target.files[0])
        const audio = await document.getElementsByTagName('audio')[1] ;
        audio.onloadedmetadata = ()=>{
            setDurationUpdate(parseInt(audio.duration));
        }
    }

    const onFinishUpdateTrack=()=>{
        if(durationUpdate != 0 && fileMusicUpdate != null){
            setDisplay(true);
            const returndata =  Update_Track_Audio(props.album.id , trackId , durationUpdate , fileMusicUpdate );
            returndata.then((res)=>{
            setDisplay(false);
            if(res.result == 'ok'){
                setVisibleUpdateTrack(false)
                emptyFields()
                props.getDetailTrack();
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
                returndata.catch((er)=>{
                Toast_noty('please try again', 5000, "error");
            })
        }
        else {
            Toast_noty('please select file', 5000, "error");
        }
        
    }


    const playTrack=(trackId)=>{
        setDisplay(true);
        const returndata =  Play_Track( props.album.id ,trackId);
            returndata.then((res)=>{
                setAudioFile(res)
                setVisiblePlay(true)
                setDisplay(false);
            })
    }

    const emptyFields=()=>{
        setAudioFile('')
        setVisiblePlay(false)
        setFileMusicUpdate(null)
        setDurationUpdate(0)
    }

    const colTrack = [
        {
            title:"Name" ,
            sort:'name' ,
            render:(i) => (<Link to={`/panel/trackdetail/${i.album_id}/${i.id}`}>{i.name != null && i.name}</Link>)
        },
        {
            title:"Creator" ,
            render:(i) => (<>{i.creator != null && i.creator.name}</>)
        },
        {
            title:"Edit by" ,
            render:(i) => (<span>{i.editor != null && i.editor.name}</span>)
        },
        {
            title:"Play" ,
            render:(i) => (<div className="table-play" onClick={()=>playTrack(i.id)}><FontAwesomeIcon icon={faPlay} /></div>)
        },
        {
            title:"Action" ,
            render:(i) => (
            <div id="table-action" className="table-action">
                <div className="table-action-icon" onClick={()=>changeDisplayActionList(i.id)}>
                    <FontAwesomeIcon icon={faEllipsisV}  />
                </div>
                <div  className={`table-action-list ${actionList == i.id && 'table-action-list-active'}`}>
                    {checkAccess(4) && 
                        <div onClick={()=>{openDeleteModal(i.id)}} className="table-action-list-item">
                            <FontAwesomeIcon icon={faTrash} />
                            <span>Delete</span>
                        </div>
                    }
                    {checkAccess(3) && 
                        <Link to={`/panel/trackedit/${i.id}`}>
                            <div  className="table-action-list-item">
                                <FontAwesomeIcon icon={faEdit} />
                                <span>Edit detail</span>
                            </div>
                        </Link>
                    }
                    {checkAccess(3) && 
                        <div onClick={()=>openUpdateTrackModal(i)} className="table-action-list-item">
                            <FontAwesomeIcon icon={faEdit} />
                            <span>Upldate audio</span>
                        </div>
                    }
                    {i.feats != null && i.feats.length > 0 && 
                        <div onClick={()=>openFitsModal(i.feats)} className="table-action-list-item">
                            <FontAwesomeIcon icon={faUser} />
                            <span>View Feats</span>
                        </div>
                    }
                    {checkAccess(3) && 
                        <div onClick={()=>openModalEditFits(i.id)} className="table-action-list-item">
                            <FontAwesomeIcon icon={faUser} />
                            <span>Edit Feats</span>
                        </div>
                    }
                    {checkAccess(3) && 
                        <div onClick={()=>openMoveModal(i.id)} className="table-action-list-item">
                            <FontAwesomeIcon icon={faArrowsAlt} />
                            <span>Move Track</span>
                        </div>
                    }
                </div>
            </div>
            )
        },
    ]

 
    useEffect(()=>{
        if(props.album){
            setListTrack(props.album.tracks)
        }

    },[props.album])


    return (
        <div className={`fullPage` }>
            {display && <Loader />}
            <div className="pagelist">
                <div className="pagelist-head">
                        <span>Album Track</span>
                        {checkAccess(2) &&
                            <Link to={`/panel/trackadd/${props.album.id}`}><button className="pagelist-head-button">Add track </button></Link>
                        }
                </div>
                <Table range={10} columns={colTrack} data={listTrack} /> 
            </div>

                <CModal onScap={emptyFields} visible={visibleMove} setVisible={setVisibleMove} title="Move Track To Album"> 
                    <Form onFinish={moveTrackFinish}>                        
                        <div className="addalbum-cont-form-section-item">
                            <span>Move to :</span>
                            <Form.Item name="albums" rules={[{ required: true}]}>
                                <Select
                                    allowClear
                                    >
                                    {listAlbum.map(item=>
                                        <Option value={item.id}>{item.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>
                        <button style={{marginTop:"10px"}} className="addalbum-cont-form-section-feat-add-btn">
                            Move
                        </button>
                    </Form>
                </CModal>

                <CModal onScap={emptyFields} visible={visiblePlay} setVisible={setVisiblePlay} title="play"> 
                    <div className="album-modal-player">
                        <div className="album-modal-player-cover">
                            {/* <ImageCover coverId={props.album.id} /> */}
                            <Image 
                                src={`${BASE_Medai_URL}/storage/cover/artist/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/album/${props.album.id}`}
                                fallback = {Placeholder}
                                preview={false}
                            />
                        </div>
                        <AudioPlayer
                            src={audioFile}
                        />
                    </div>
                    <div className="CModel-Bottom">
                        <button className="CModel-Bottom-btn-cancel" onClick={emptyFields}>Close</button>
                    </div>
                </CModal>

                <CModal  visible={visibleDelete} setVisible={setVisibleDelete} title="Delete Track"> 
                    <p>Are you sure delete Track {deleteId} ? </p>
                    <div className="CModel-Bottom">
                        <button className="CModel-Bottom-btn-ok" onClick={onFinishDelete} >Ok</button>
                        <button className="CModel-Bottom-btn-cancel" onClick={()=>{setVisibleDelete(false)}}>Close</button>
                    </div>
                </CModal>

                <CModal  visible={visibleFits} setVisible={setVisibleFits} title="Feats"> 
                    <div className="album-modal-fits">
                        <div className="album-modal-fits-head">
                            <div className="album-modal-fits-head-sec">Artists</div>
                            <div className="album-modal-fits-head-sec">Percentage</div>
                        </div>
                        {listFits.length > 0 && listFits.map(item=>
                            <div className="album-modal-fits-item">
                                <div className="album-modal-fits-item-sec">{item.name}</div>
                                <div className="album-modal-fits-item-sec">{item.pivot.percentage} %</div>
                            </div>
                        )}
                    </div>
                </CModal>

                <CModal  visible={visibleEditFits} setVisible={setVisibleEditFits} title="EditFits"> 
                    <div className="addalbum-cont-form-section-feat">
                        <Form onFinish={onfinishEditFits}>                        
                            <Form.List name="feats">
                                {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                        <Form.Item
                                        {...restField}
                                        name={[name, 'artist_id']}
                                        fieldKey={[fieldKey, 'artist_id']}
                                        rules={[{ required: true, }]}
                                        >
                                            <Select
                                                placeholder="Select artist"
                                                allowClear
                                                >
                                                    {listArtists.map((itemArtist , indexArtist)=>
                                                        <Option key={indexArtist} value={itemArtist.id}>{itemArtist.name}</Option>
                                                    )}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                        {...restField}
                                        name={[name, 'percentage']}
                                        fieldKey={[fieldKey, 'percentage']}
                                        rules={[{ required: true}]}
                                        >
                                        <InputNumber placeholder="percentage" />
                                        </Form.Item>
                                        <FontAwesomeIcon icon={faMinus} onClick={() => remove(name)} />
                                    </Space>
                                    ))}
                                    <button type="button" className="addalbum-cont-form-section-feat-add-btn"  onClick={() => add()}>
                                        Add feats
                                    </button>
                                </>
                                )}
                            </Form.List>
                            <button style={{marginTop:"10px"}} className="addalbum-cont-form-section-feat-add-btn">
                                Add
                            </button>
                        </Form>
                    </div>
                </CModal>

                <CModal onScap={emptyFields} visible={visibleUpdateTrack} setVisible={setVisibleUpdateTrack} title="Update"> 
                    <div className="albumdetail-update-modal">
                        {fileMusicUpdate == null ? 
                            <label>
                                <input accept=".mp3" onChange={(e)=>selectFileForUpdateTrack(e)} type="file" required />
                                <span>+ Select music file</span>
                            </label>
                        :
                            <div className="album-modal-player">
                                <div className="album-modal-player-cover">
                                    {/* <ImageCover coverId={props.album.id} /> */}
                                    <Image 
                                        src={`${BASE_Medai_URL}/storage/cover/artist/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/album/${props.album.id}`}
                                        fallback = {Placeholder}
                                        preview={false}
                                    />
                                    <span>{trackUpdateName}</span>
                                </div>
                                <AudioPlayer
                                    src={URL.createObjectURL(fileMusicUpdate)}
                                />
                                <button className="album-modal-player-btn" onClick={onFinishUpdateTrack}>Update</button>
                            </div>
                        }

                    </div>
                </CModal>

        </div> 
    )
}

export default AlbumDetailTrack;