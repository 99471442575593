import React, { useEffect, useState , useCallback } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../widget/Toast_noty'
import Profile from '../../../static/img/profile.png'
import {Get_Single_Users , Edit_User , Get_Roles , Update_Cover_User} from '../../../widget/API';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCloudUploadAlt, faLock, faTrash , faEye } from "@fortawesome/free-solid-svg-icons";
import Cropper from 'react-easy-crop'
import getCroppedImg from '../../../widget/cropImage'
import CModal from '../../../widget/CModal/CModal';
import Loader from "../../../widget/Loader/Loader";
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import ImageUser from '../../../widget/ImageUser'
import '../userStyle.css';


function UserEdit(props) {
    const[dispaly , setDisplay]=useState(false);
    const[listRoles , setlistRoles]=useState([]);
    const [name , setName]=useState('');
    const [email , setEmail]=useState('');
    const [phone , setPhone]=useState('');
    const [role , setRole]=useState('');

    const [fileList, setFileList] = useState([]);


    // Function For Upload Handel

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
      };
    
    const onPreview = async file => {
        let src = file.url;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
      };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      };

    // End Function For Upload 


    const uploadImage=()=>{
        if(fileList.length > 0){
            setDisplay(true);
            const returndata =  Update_Cover_User(props.match.params.id , fileList);
            returndata.then(async(res)=>{
            setDisplay(false);
            if(res.result == 'ok'){
                Toast_noty("Changes saved successfully", 10000, "success");
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
            returndata.catch((er)=>{
                setDisplay(false);
                Toast_noty('please try again', 5000, "error");
            })
        }
    }



    const finishEdit=(e)=>{
        e.preventDefault();
        setDisplay(true);
        const returndata =  Edit_User(props.match.params.id , name , email , phone , role);
        returndata.then(async(res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            await getData();
            Toast_noty("Changes saved successfully", 10000, "success");
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }


    const getRols=()=>{
        const returndata =  Get_Roles();
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setlistRoles(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }

    const getData=()=>{
        const returndata =  Get_Single_Users(props.match.params.id);
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setName(res.data.name);
            setEmail(res.data.email);
            setPhone(res.data.phone);
            setRole(res.data.role_id);
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }


    useEffect(()=>{
        getData()
        getRols()
      },[])


    return (
        <div className={`fullPage` }>
          {dispaly && <Loader/>}  
          <div className="useradd">
              <div className="useradd-head">
                  <div className="useradd-head-edit-pro">
                        <ImgCrop>
                            <Upload
                            className="mt-2"
                            accept=".png , .jpg , .jpeg , .pdf "
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={onPreview}
                            customRequest={dummyRequest}
                            showUploadList={{showPreviewIcon:false}}
                            >
                                {fileList.length < 1 &&
                                    <ImageUser coverId={props.match.params.id} />
                                }
                            </Upload>
                        </ImgCrop>
                  </div>
                  <div className="useradd-head-edit-detail">
                      <h3>{name} </h3>
                      {fileList.length > 0 && 
                        <button onClick={uploadImage}>Upload</button>
                       }
                  </div>
              </div>
              <form onSubmit={finishEdit} className="useradd-form">

                <div className="useradd-form-one">
                    <div className="useradd-form-one-item">
                        <span>Email</span>
                        <input type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
                    </div>
                    <div className="useradd-form-one-item">
                        <span>Name</span>
                        <input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} />
                    </div>
                    <div className="useradd-form-one-item">
                        <span>Role</span>
                        <select value={role} onChange={(e)=>{setRole(e.target.value)}} required>
                            <option value="">--</option>
                            {listRoles.map(item=>
                                <option value={item.id}>{item.name}</option>
                            )}
                        </select>
                    </div>
                    <div className="useradd-form-one-item">
                        <span>Phone</span>
                        <input type="tel"  value={phone} onChange={(e)=>{setPhone(e.target.value)}} />
                    </div>
                </div>  

                <div className="useradd-form-bottom">
                    <button>Save change</button>
                </div>
                
              </form>
          </div>
        </div>
    )
}

export default UserEdit;