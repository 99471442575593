import React, { useEffect, useRef, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faSearch, faTable , faStar, faHeart, faMusic, faChevronRight, faChevronLeft, faEye } from "@fortawesome/free-solid-svg-icons";
import {Get_All_Album} from '../../../widget/API';
import { BASE_Medai_URL } from "../../../widget/BASE_Medai_URL";
import moment from "moment";
import ImageCover from '../../../widget/ImageCover';
import Placeholder from '../../../static/img/placeholder.png'
import { Link } from "react-router-dom";
import '../albumStyle.css';
import {Image} from 'antd'
import SkeletonAlbum from '../../../widget/skeleton/SkeletonAlbum';



function AlbumList(props) {
    const[dispaly , setDisplay]=useState(false);
    const[rowDisplay , setRowDisplay]=useState(false);
    const[listAlbum , setListAlbum]=useState([]);
    const[listAlbumKeeper , setListAlbumKeeper]=useState([]);
    const [currentPage , setCurrentPage]=useState(1);
    const [lastPage , setLastPage]=useState(0);



    const changePage=(status)=>{
        if(status){
            if(lastPage >= currentPage + 1){
                getData(currentPage + 1)
            }
        }
        else {
            if(currentPage > 1){
                getData(currentPage - 1)
            }
        }
    }
 

    const checkResizePage=()=>{
        if(window.innerWidth < 770){
            setRowDisplay(false)
        }
    }


    const searchManual=(value)=>{
        setListAlbum(
            listAlbumKeeper.filter(value1 => {
               return !!( value1.name.toUpperCase().includes(value.toUpperCase()));
            })
        )
    }



    React.useEffect(() => {
        window.addEventListener("resize", checkResizePage);
        return () => window.removeEventListener("resize" , checkResizePage);
      });


    const getData=(current)=>{
        setDisplay(true);
        const returndata =  Get_All_Album(current);
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            if(res.data.data.length > 0){
                setListAlbum(res.data.data);
                setListAlbumKeeper(res.data.data)
                setCurrentPage(res.data.current_page)
                setLastPage(res.data.last_page)
                window.scrollTo({top: 0 })
            }
            else {
                Toast_noty("data not found", 5000, "error");
            }
            
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
            returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }  


    useEffect(()=>{
        getData(currentPage)
    },[])

      

    return (
        <div className="album">
            <div className="album-head">
                <div className="album-head-search">
                    <FontAwesomeIcon icon={faSearch} />
                    <input onChange={(e)=>searchManual(e.target.value)} type="text" placeholder="Search"/>
                </div>
                <div className="album-head-line">
                    <div onClick={()=>{setRowDisplay(true)}} className={`album-head-line-item ${rowDisplay && 'album-head-line-item-active'}`}>
                        <FontAwesomeIcon icon={faList} />
                    </div>
                    <div onClick={()=>{setRowDisplay(false)}} className={`album-head-line-item ${!rowDisplay && 'album-head-line-item-active'}`}>
                        <FontAwesomeIcon icon={faTable} />
                    </div>
                </div>
            </div>
            <div className="album-cont">
                {dispaly && <SkeletonAlbum />}
                {dispaly == false && listAlbum.map((item , index)=>
                        <div className={`album-cont-item ${rowDisplay && 'album-cont-item-row'}`}>
                            <Link to={`/panel/albumdetail/${item.id}`}>
                            <div className="album-cont-item-inner">
                                <div className="album-cont-item-inner-img">
                                    {/* <ImageCover coverId={item.id} /> */}
                                    {/* <img src={`${BASE_Medai_URL}/storage/cover/artist/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/album/${item.id}`} /> */}
                                    <Image 
                                        src={`${BASE_Medai_URL}/storage/cover/artist/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/album/${item.id}`}
                                        fallback = {Placeholder}
                                        preview={false}
                                    />
                                </div>
                                <div className="album-cont-item-inner-cont">
                                    <div className="album-cont-item-inner-head">
                                        <div className="album-cont-item-inner-head-rate">
                                            <FontAwesomeIcon icon={faStar} />
                                            <FontAwesomeIcon icon={faStar} />
                                            <FontAwesomeIcon icon={faStar} />
                                            <FontAwesomeIcon icon={faStar} />
                                            <FontAwesomeIcon icon={faStar} />
                                        </div>
                                    </div>
                                    <div className="album-cont-item-inner-title">
                                        <h3>{item.name}</h3>
                                    </div>
                                    <div className="album-cont-item-inner-des">
                                        <span>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua
                                        </span>
                                    </div>
                                    <div className="album-cont-item-inner-time">
                                        <span>release : {moment(item.release_time).format('YYYY-MM-DD hh:mm')}</span>
                                    </div>
                                </div>
                                <div className="album-cont-item-inner-bottom">
                                    <div className="album-cont-item-inner-bottom-item">
                                        <FontAwesomeIcon icon={faHeart} />
                                        <span>{item.fake_like}</span>
                                    </div>
                                    <div className="album-cont-item-inner-bottom-item">
                                        <FontAwesomeIcon icon={faEye} />
                                        <span>{item.views_count}</span>
                                    </div>
                                    <div className="album-cont-item-inner-bottom-item">
                                        <FontAwesomeIcon icon={faMusic} />
                                        <span>{item.tracks_count}</span>
                                    </div>
                                </div>
                            </div>
                            </Link>
                        </div>
                )}
            </div>

            <div className="pagining">
               <div className="pagining-inner">
                    <div onClick={()=>changePage(false)} className="pagining-inner-side"><FontAwesomeIcon icon={faChevronLeft} /></div>
                    <div className="pagining-inner-center">{currentPage}</div>
                    {lastPage >= currentPage + 1 && 
                        <div onClick={()=>changePage(true)} className="pagining-inner-side"><FontAwesomeIcon icon={faChevronRight} /></div>
                    }
               </div>
            </div>

        </div>
    )
}

export default AlbumList;