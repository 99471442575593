import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../widget/Toast_noty'
import Table from '../../../widget/Table/Table';
import {Get_Users , Delete_User , Change_Available} from '../../../widget/API';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEllipsisH, faEllipsisV, faList, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import CModal from '../../../widget/CModal/CModal';
import Loader from "../../../widget/Loader/Loader";
import '../userStyle.css';
import checkAccess from '../../../widget/checkAccess';

function UserList(props) {
    const[dispaly , setDisplay]=useState(false);
    const[visibleDelete , setVisibleDelete]=useState(false);
    const[deleteId , setDeleteId]=useState(0);
    const[listUsers , setListUsers]=useState([]);
    const[keepListUser , setKeepListUser]=useState([]);
    const[actionList , setActionList]=useState(-1);
    const[range , setRange]=useState(10);

    const changeDisplayActionList=(id)=>{
        if(actionList == id){
            setActionList(-1)
        }
        else {
            setActionList(id)
        }
    }

    const openModal=(id)=>{
        setVisibleDelete(true);
        setDeleteId(id)
    }


    const deleteUser=async()=>{
        setDisplay(true);
        setVisibleDelete(false)
        const returndata =  Delete_User(deleteId);
        returndata.then(async(res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            await getData()
            Toast_noty("Delete user success", 10000, "success");
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }


    const searchManual=(value)=>{
            setListUsers(
                keepListUser.filter(value1 => {
                   return !!(value1.id.toString().includes(value) || value1.name.toString().includes(value));
                })
        )
    }

    const changeAvailable=(id)=>{
        setDisplay(true);
        const returndata =  Change_Available(id);
        returndata.then(async(res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            await getData()
            Toast_noty("Changes saved successfully", 10000, "success");
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }

    const getData=()=>{
        const returndata =  Get_Users();
        returndata.then((res)=>{
        if(res.result == 'ok'){
            setListUsers(res.data)
            setKeepListUser(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }


    useEffect(()=>{
        getData()
      },[])



      const col = [
        {
            title:"id" ,
            sort:'id' ,
            render:(i) => (<>{i.id != null && i.id}</>)
        },
        {
            title:"Name" ,
            sort:'name' ,
            render:(i) => (
                <Link to={`/panel/user/${i.id}`}>
                    <div className="table-profile">
                        <div>{i.name != null && i.name.substr(0, 1)}</div>
                        <span>{i.name != null && i.name}</span>
                    </div>
                </Link>
                )
        },
        {
            title:"Email" ,
            render:(i) => (<>{i.email != null && i.email}</>)
        },
        {
            title:"Role" ,
            render:(i) => (<span>{i.role != null && i.role.name}</span>)
        },
        {
            title:"Active" ,
            render:(i) => (
              
            <label className="table-toggle">
                {checkAccess(7) &&
                    <>
                        <input type="checkbox" onChange={()=>{changeAvailable(i.id)}} checked={i.available && true}/>
                        <div className="table-toggle-main" />
                    </>
                }
            </label>)
        },
        {
            title:"Action" ,
            render:(i) => (
            <div id="table-action" className="table-action">
                <div className="table-action-icon" onClick={()=>changeDisplayActionList(i.id)}>
                    <FontAwesomeIcon icon={faEllipsisV}  />
                </div>
                <div  className={`table-action-list ${actionList == i.id && 'table-action-list-active'}`}>
                    {checkAccess(8) && 
                        <div onClick={()=>openModal(i.id)} className="table-action-list-item">
                            <FontAwesomeIcon icon={faTrash} />
                            <span>Delete</span>
                        </div>
                    }
                    {checkAccess(7) && 
                        <Link to={`/panel/useredit/${i.id}`}>
                            <div className="table-action-list-item">
                                <FontAwesomeIcon icon={faEdit} />
                                <span>Edit</span>
                            </div>
                        </Link>
                    }
                </div>
            </div>
            )
        },
    ]


    return (
        <div className={`fullPage` }>
            {dispaly && <Loader/>}
            <CModal visible={visibleDelete} setVisible={setVisibleDelete} title="Delete"> 
                <p>Are you sure delete user {deleteId} ?</p>
                <div className="CModel-Bottom">
                    <button className="CModel-Bottom-btn-ok" onClick={deleteUser}>Ok</button>
                    <button className="CModel-Bottom-btn-cancel" onClick={()=>{setVisibleDelete(false)}}>Cansel</button>
                </div>
            </CModal>
          <div className="pagelist">
              <div className="pagelist-head">
                    <div className="pagelist-head-entry">
                        {/* <span>Show</span>
                        <select onChange={(e)=>{setRange(e.target.value);}}>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                        </select>
                        <span>entries</span> */}
                    </div>
                    <div className="pagelist-head-search">
                        <input onChange={(e)=>searchManual(e.target.value)} type="text" placeholder="Search Name"/>
                        {checkAccess(6) && 
                            <Link to='/panel/useradd'><button className="pagelist-head-button">Add user</button></Link>
                        }
                    </div>
              </div>
              <Table range={range} columns={col} data={listUsers} />
          </div>  
        </div>
    )
}

export default UserList;