import React, { useEffect, useState , useCallback } from "react" ;
import {ToastContainer} from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCloudUploadAlt, faLock, faTrash , faEye } from "@fortawesome/free-solid-svg-icons";
import Toast_noty from '../../../widget/Toast_noty'
import {Add_Role} from '../../../widget/API';
import Loader from "../../../widget/Loader/Loader";
import '../roleStyle.css'


function UserEdit(props) {
    const[display , setDisplay]=useState(false);
    const[listId , setListId]=useState([]);

    const checkListId=(id)=>{
        let listper = listId ;
        for(let i in listper){
            if(listper[i] == id){
                listper.splice(i , 1);
                return
            }
        }
        listper.push(id);
        setListId(listper);
        return
    }


    const addRole=(e)=>{
        e.preventDefault();
        if(listId.length > 0){
            setDisplay(true);
            const returndata =  Add_Role(listId , e);
            returndata.then(async(res)=>{
            setDisplay(false);
            if(res.result == 'ok'){
                props.history.push('/panel/rolelist');  

            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
            returndata.catch((er)=>{
                setDisplay(false);
                Toast_noty('please try again', 5000, "error");
            })
        }
        else {
            Toast_noty("Select permission", 5000, "error");
        }
    }


    return (
        <div className={`fullPage` }>
          {display && <Loader/>}
          <div className="roleadd">
              <form onSubmit={addRole} className="roleadd-form">

                <div className="roleadd-form-one">

                    <div className="roleadd-form-one-item">
                        <span>Role Name</span>
                        <input type="text" required/>
                    </div>
                </div>  

                <div className="roleadd-form-per">
                    <div className="roleadd-form-per-head">
                        <FontAwesomeIcon icon={faLock} />
                        <h4>Permission</h4>
                    </div>

                    <div className="roleadd-form-per-row">
                        <div className="roleadd-form-per-row-item">Module</div>
                        <div className="roleadd-form-per-row-item">See</div>
                        <div className="roleadd-form-per-row-item">Add</div>
                        <div className="roleadd-form-per-row-item">Update</div>
                        <div className="roleadd-form-per-row-item">Delete</div>

                    </div>

                    <div className="roleadd-form-per-row">
                        <div className="roleadd-form-per-row-item">Album & Track</div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(1)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(2)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(3)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(4)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className="roleadd-form-per-row">
                        <div className="roleadd-form-per-row-item">Users</div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(5)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(6)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(7)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(8)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className="roleadd-form-per-row">
                        <div className="roleadd-form-per-row-item">Roles</div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(9)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(10)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(11)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(12)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className="roleadd-form-per-row">
                        <div className="roleadd-form-per-row-item">Podcast</div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(13)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(14)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(15)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(16)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className="roleadd-form-per-row">
                        <div className="roleadd-form-per-row-item">Music Video</div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(17)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(18)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(19)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(20)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className="roleadd-form-per-row">
                        <div className="roleadd-form-per-row-item">Follwer</div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(21)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" disabled />
                                <div className="roleadd-form-per-row-item-checkbox roleadd-form-per-row-item-checkbox-deisable">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" disabled />
                                <div className="roleadd-form-per-row-item-checkbox roleadd-form-per-row-item-checkbox-deisable">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" disabled />
                                <div className="roleadd-form-per-row-item-checkbox roleadd-form-per-row-item-checkbox-deisable">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className="roleadd-form-per-row">
                        <div className="roleadd-form-per-row-item">Artist</div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" disabled />
                                <div className="roleadd-form-per-row-item-checkbox roleadd-form-per-row-item-checkbox-deisable">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" disabled />
                                <div className="roleadd-form-per-row-item-checkbox roleadd-form-per-row-item-checkbox-deisable">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>    
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" onChange={()=>checkListId(22)} />
                                <div className="roleadd-form-per-row-item-checkbox">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                        <div className="roleadd-form-per-row-item">
                            <label>
                                <input type="checkbox" disabled />
                                <div className="roleadd-form-per-row-item-checkbox roleadd-form-per-row-item-checkbox-deisable">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                            </label>
                        </div>
                    </div>

                </div>

                

                <div className="roleadd-form-bottom">
                    <button>Add Role</button>
                </div>
                
              </form>
          </div>
        </div>
    )
}

export default UserEdit;