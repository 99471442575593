import React, { useEffect, useRef, useState } from "react" ;
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";
import { Select , DatePicker, Space , InputNumber , Form , Input} from 'antd';
import { faEdit, faFileImage, faHeart, faMailBulk, faMoneyBill, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Get_Podcast_Detail , Get_Gender_Album , Get_Category_Album , Edit_Podcast} from '../../../widget/API';
import PodcastAutoFields from '../PodcastAutoFields'

import '../podcastStyle.css';




function PodcastEdit(props) {
    const[dispaly , setDisplay]=useState(false);
    const [podcast , setPodcast]=useState(null);
    const [listArtists , setListArtists]=useState([])
    const[listCat , setListCat]=useState([]);
    const[listGender , setListGender]=useState([]);
    // const[feats , setFeats]=useState(false);
    const[pay , setPay]=useState(false);

    const { Option } = Select;
    const [form] = Form.useForm();

    const onFinishEdit=(values)=>{
        if(values && values.labels){
            values['labels'] =  Object.entries(values.labels).map(([key , value])=>{
                if(value && value._d){
                    return {
                        "id" : key,
                        "value" : String(moment(value._d).format('YYYY-MM-DD hh:mm')),
                    }
                }
                else {
                    return {
                        "id" : key,
                        "value" : String(value),
                    }
                }
                
            })
        }
        values.release_time = moment(values.release_time._d).format('YYYY-MM-DD hh:mm')
        setDisplay(true);
        const returndata =  Edit_Podcast(values , props.match.params.id , pay);
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            props.history.push(`/panel/podcastdetail/${props.match.params.id}`);  
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })

    }




    const getDetailPodcast=()=>{
        if(props.match.params.id){
            setDisplay(true)
            const returndata =  Get_Podcast_Detail(props.match.params.id);
            returndata.then((res)=>{
            setDisplay(false)
            if(res.result == 'ok'){
                setPodcast(res.data)
                if(res.data.product != null){
                    setPay(true);
                    form.setFieldsValue({'pay' : true})
                    form.setFieldsValue({'Rial_price' : res.data.product.price_rial})
                    form.setFieldsValue({'Dollar_price' : res.data.product.price_dollar})
                    form.setFieldsValue({'vip' : res.data.product.vip})

                }
                else {
                    form.setFieldsValue({'pay' : false})
                }
                form.setFieldsValue({'name' : res.data.name})
                form.setFieldsValue({'director' : res.data.director})
                form.setFieldsValue({'release_time' : moment(res.data.release_time)})
                form.setFieldsValue({'gender_id' : res.data.gender_id})
                form.setFieldsValue({'producer' : res.data.producer})
                form.setFieldsValue({'cover_producer' : res.data.cover_producer})    
                if(res.data.labels){
                    for(let i of res.data.labels){
                        if(i.label && i.label != null && i.label.property.type == 'enum' && i.label.property.choose == 'single'){
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : parseInt(i.value)}})
                        }
                        else if(i.label && i.label != null && i.label.property.type == 'enum' && i.label.property.choose == 'multi'){
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : 
                                i.value.split(",").map(item=>{
                                    return parseInt(item)
                                })
                            }})
                        }
                        else if (i.label && i.label != null && i.label.property.type == 'date_time') {
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : moment(i.value)}})
                        }
                        else if(i.label && i.label != null ) {
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : i.value}})
                        }
                    }
                }   
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
             returndata.catch((er)=>{
                setDisplay(false)
                Toast_noty('please try again', 5000, "error");
            })
        }
    }


    const getCategory=()=>{
        setDisplay(true);
        const returndata =  Get_Category_Album();
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setListCat(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }

    const getGender=()=>{
        setDisplay(true);
        const returndata =  Get_Gender_Album();
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setListGender(res.data)
        }
        })
    }



    useEffect(()=>{
        if(props.match.params.id){
            getDetailPodcast()
            getGender()
            getCategory()
        }
    },[props.match.params.id])





    return (
        <div className="podcastdetail">
            {dispaly && <Loader/>}
            <div className="addpodcast-cont">
                <Form onFinish={onFinishEdit} form={form}  className="addpodcast-cont-form">
                    <div className="addpodcast-cont-form-section">


                    <div className="addpodcast-cont-form-section-item">
                            <span>Podcast name</span>
                            <Form.Item name="name" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <div className="addpodcast-cont-form-section-item">
                            <span>Producer</span>
                            <Form.Item name="producer" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <PodcastAutoFields/>

                        <div className="addpodcast-cont-form-section-item">
                            <span>Category</span>
                            <Form.Item name="category" >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    >
                                    {listCat.map(item=>
                                        <Option value={item.id}>{item.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="addpodcast-cont-form-section-item">
                            <span>Gender</span>
                            <Form.Item name="gender_id" rules={[{ required: true}]}>
                                <Select
                                    >
                                    {listGender.map(item=>
                                        <Option value={item.id}>{item.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className="addpodcast-cont-form-section-item">
                            <span>Cover producer</span>
                            <Form.Item name="cover_producer" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <div className="addpodcast-cont-form-section-item">
                            <span>Release time</span>
                            <Form.Item name="release_time" rules={[{ required: true}]}>
                                <DatePicker />
                            </Form.Item>
                        </div>

                        {/* <div className="addpodcast-cont-form-section-item">
                            <span>Pay status</span>
                            <Form.Item name="pay" rules={[{ required: true}]}>
                                <Select
                                    allowClear
                                    onChange={(value)=>{setPay(value)}}
                                    >
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </div> */}

                        {/* {pay && 
                        <div className="addpodcast-cont-form-section-item">
                            <span>Rial price</span>
                            <Form.Item name="Rial_price" rules={[{ required: true}]}>
                                <InputNumber />
                            </Form.Item>
                        </div>
                        } */}

                        {/* {pay && 
                        <div className="addpodcast-cont-form-section-item">
                            <span>Dollar price</span>
                            <Form.Item name="Dollar_price" rules={[{ required: true}]}>
                                <InputNumber />
                            </Form.Item>
                        </div>
                        } */}

                        {/* {pay && 
                        <div className="addpodcast-cont-form-section-item">
                            <span>Vip</span>
                            <Form.Item name="vip" rules={[{ required: true}]}>
                                <Select
                                    allowClear
                                    >
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </div>
                        } */}

                    </div>
                    <div className="addpodcast-cont-form-footer"><button className="addpodcast-cont-form-footer-btn-next">Edit</button></div>
                </Form>
            </div>
        </div>
    )
}

export default PodcastEdit;