import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../../widget/Toast_noty'
import Profile from '../../../../static/img/profile.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage, faMailBulk, faMoneyBill, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import CModal from "../../../../widget/CModal/CModal";
import { Link , Redirect} from "react-router-dom";
import {Delete_User } from '../../../../widget/API';
import Loader from '../../../../widget/Loader/Loader';
import Table from '../../../../widget/Table/Table'


function UserDetailAlbum(props) {
    const[dispaly , setDisplay]=useState(false);

    const colAlbum = [
        {
            title:"Name" ,
            sort:'name',
            render:(i) => (<>{i.name != null && i.name}</>)
        },
        {
            title:"Producer" ,
            render:(i) => (<>{i.producer != null && i.producer}</>)
        },
        {
            title:"Album" ,
            render:(i) => (<>{i.album != null && i.album.name}</>)
        },
        {
            title:"Created by" ,
            render:(i) => (<>{i.created_by != null &&
                <span className="table-tag table-tag-3">Create by you</span>
            }</>)
        },
        {
            title:"Update by" ,
            render:(i) => (<>{i.updated_by != null && i.updated_by == JSON.parse(localStorage.getItem('userInfo')).id &&
                <span className="table-tag table-tag-3">update by you</span>
            }</>)
        },
    ]


    return (
        <div className="fullPage">
            {props.detailUser && props.detailUser.tracks != null && 
            <div className="pagelist">
                <div className="pagelist-head">
                    <span>Album Track</span>
                </div>
                <Table range={10} columns={colAlbum} data={props.detailUser.tracks} />
            </div>
            }
        </div>     
    )
}

export default UserDetailAlbum;