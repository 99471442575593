import React, { useEffect, useState , useCallback } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../../widget/Toast_noty'
import Loader from "../../../../widget/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faSearch, faTable , faStar, faHeart, faMusic, faChevronRight, faUpload } from "@fortawesome/free-solid-svg-icons";
import {Add_Album_Two} from '../../../../widget/API';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';





function AlbumAddStepTwo(props) {
    const[dispaly , setDisplay]=useState(false);
    const[visibleCrop , setVisibleCrop]=useState(false);
    const [fileList, setFileList] = useState([]);


        // Function For Upload Handel

        const onChange = ({ fileList: newFileList }) => {
            setFileList(newFileList);
            props.setCoverFile(newFileList)
          };
        
        const onPreview = async file => {
            let src = file.url;
            if (!src) {
              src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
              });
            }
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow.document.write(image.outerHTML);
          };
    
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
              onSuccess("ok");
            }, 0);
          };
    
        // End Function For Upload 



    const onFinish=()=>{
        setDisplay(true);
        const returndata =  Add_Album_Two(props.albumId , fileList );
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            props.setStep(3);
        }
        else 
        {
            Toast_noty(res.error, 5000, "error");
        }
        })
        returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }

    return (
            <div className="addalbum-cont">
                {dispaly && <Loader/>}
                {/* <div className="addalbum-cont-head">
                    <h3>Add cover</h3>
                </div> */}
                    <div className="addalbum-cont-upload">
                        <ImgCrop>
                            <Upload
                            className="mt-2"
                            accept=".png , .jpg , .jpeg , .pdf "
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={onPreview}
                            customRequest={dummyRequest}
                            showUploadList={{showPreviewIcon:false}}
                            >
                                {fileList.length < 1 &&
                                    <div className="">
                                        <h3>Click button to upload cover.</h3>
                                        <FontAwesomeIcon icon={faUpload} />
                                    </div>
                                }
                            </Upload>
                        </ImgCrop>
                        {fileList.length > 0 && 
                            <div className="addalbum-cont-upload-footer">
                                <button className="addalbum-cont-form-footer-btn-next" onClick={onFinish}>Next Step</button>
                            </div>
                        }
                    </div>
            </div>
    )
}

export default AlbumAddStepTwo;