import React, { useState } from "react" ;
import {Get_User_Cover_Image} from './API';

function ImageUser(props) {
    const [image , setImage]=useState('');



    React.useEffect(() => {
        if(props.coverId){
            const returndata =  Get_User_Cover_Image(props.coverId);
            returndata.then((res)=>{
                setImage(res)
            })
        }
        
    }, [props.coverId])



    return (
        <img src={image} loading="lazy" alt="cover"/>
    )
}

export default ImageUser;