import React, { useEffect, useRef, useState } from "react" ;
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";

import { Get_Podcast_Detail} from '../../../widget/API';
import {Link} from 'react-router-dom';
import PodcastDetailHead from "./widget/PodcastDetailHead";
import CModal from "../../../widget/CModal/CModal";
import PodcastDetailCont from './widget/PodcastDetailCont';
import PodcastDetailFeat from './widget/PodcastDetailFeat';
import PodcastDetailViews from './widget/PodcastDetailViews'
import PodcastDetailLikes from './widget/PodcastDetailLikes'
import SkeletonSection from '../../../widget/skeleton/SkeletonSection';

import '../podcastStyle.css';


function PodcastDetail(props) {
    const[dispaly , setDisplay]=useState(false);
    const [podcast , setPodcast]=useState(null);





    const getDetailPodcast=()=>{
        if(props.match.params.id){
            setDisplay(true)
            const returndata =  Get_Podcast_Detail(props.match.params.id);
            returndata.then((res)=>{
            setDisplay(false)
            if(res.result == 'ok'){
                setPodcast(res.data)
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
             returndata.catch((er)=>{
                setDisplay(false)
                Toast_noty('please try again', 5000, "error");
            })
        }
    }


    useEffect(()=>{
        getDetailPodcast();
      },[])

    return (
        <div className="podcastdetail">
            {/* {dispaly && <Loader/>} */}
            {podcast == null && <SkeletonSection/>}
            {podcast == null && <SkeletonSection/>}
            {podcast != null && <PodcastDetailHead podcast={podcast} /> }
            {podcast != null &&<PodcastDetailCont podcast={podcast} getDetailPodcast={getDetailPodcast} />}
            {podcast != null &&<PodcastDetailFeat podcast={podcast} getDetailPodcast={getDetailPodcast} />}
            {podcast != null && <PodcastDetailLikes podcast={podcast}  />}
            {podcast != null &&<PodcastDetailViews podcast={podcast}  />}
        </div>
    )
}

export default PodcastDetail;