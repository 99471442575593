import React, { useEffect, useState } from "react" ;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import TablePage from "../../../../widget/Table/TablePage";
import {Get_Music_Video_Likes} from '../../../../widget/API';
import Toast_noty from '../../../../widget/Toast_noty'



function MusicVideoDetailLikes(props) {
    const[listLikes , setListLikes]=useState([]);
    const [currentPage , setCurrentPage]=useState(1);
    const [lastPage , setLastPage]=useState(0);

    const changePage=(status)=>{
        if(status){
            if(lastPage >= currentPage + 1){
                getData(currentPage + 1)
            }
        }
        else {
            if(currentPage > 1){
                getData(currentPage - 1)
            }
        }
    }


    const colLike = [
        {
            title:"Name" ,
            render:(i) => (<>{i.name != null && i.name}</>)
        },
        {
            title:"Email" ,
            render:(i) => (<>{i.email != null && i.email}</>)
        },
    ]


    const getData=(current)=>{
        const returndata =  Get_Music_Video_Likes(props.musicVideo.id , current);
        returndata.then((res)=>{
        if(res.result == 'ok'){
            setListLikes(res.data.data)
            setCurrentPage(res.data.current_page)
            setLastPage(res.data.last_page)

        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
    }


    useEffect(()=>{
        if(props.musicVideo){
            getData(currentPage)
        }

    },[props.musicVideo])


    return (
        <div className="fullPage">
            <div className="pagelist">
                <div className="pagelist-head">
                    <span>Music Video Likes</span>
                </div>
                <TablePage columns={colLike} data={listLikes}>
                    {currentPage != 1 && 
                        <div className="table-counter-main-item" onClick={()=>changePage(false)} ><FontAwesomeIcon icon={faChevronLeft} /></div>
                    }
                        <div className="table-counter-main-item">{currentPage} </div>
                    {lastPage >= currentPage + 1 && 
                        <div className="table-counter-main-item" onClick={()=>changePage(true)} ><FontAwesomeIcon icon={faChevronRight} /></div>
                    }
                </TablePage>
            </div>
        </div> 
    )
}

export default MusicVideoDetailLikes;