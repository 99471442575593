
import './loaderStyle.css';


function LoaderBtn() {

    return (
        <div id="loading-bar-spinner" class="spinner">
            <div class="spinner-icon"></div>
        </div>
    )
}

export default LoaderBtn;