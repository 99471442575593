import { faAddressBook, faTachometerAlt, faCircleNotch, faChevronRight, faShoppingBag, faList, faShoppingCart, faSearch, faEdit, faUser, faLock, faImage, faImages } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { render } from "@testing-library/react";
import React  from "react" ;


const NavDataSearch = [
    {
        'name':"Dashboard" ,
        'link':'/panel' ,
        'permissions':-2 ,
    },
    {
        'name':"List User" ,
        'link':'/panel/userlist' ,
        'permissions':5 ,

    },
    {
        'name':"Add User" ,
        'link':'/panel/useradd' ,
        'permissions':6,

    },

    {
        'name':"Add Role" ,
        'link':'/panel/roleadd' ,
        'permissions':10,
    },
    {
        'name':"List Role" ,
        'link':'/panel/rolelist' ,
        'permissions':9,
    },
    {
        'name':"Add Album" ,
        'link':'/panel/albumadd' ,
        'permissions':2,
    },
    {
        'name':"List Album" ,
        'link':'/panel/albumlist' ,
        'permissions':1,
    },
    {
        'name':"List Track" ,
        'link':'/panel/tracklist' ,
        'permissions':1,
    },
    {
        'name':"List Musicvideo" ,
        'link':'/panel/musicvideolist' ,
        'permissions':17,
    },
    {
        'name':"Add Musicvideo" ,
        'link':'/panel/musicvideoadd' ,
        'permissions':18,
    },
    {
        'name':"List Podcast" ,
        'link':'/panel/podcastlist' ,
        'permissions':13,
    },
    {
        'name':"Add Podcast" ,
        'link':'/panel/podcastadd' ,
        'permissions':14,
    },
    {
        'name':"List Followers" ,
        'link':'/panel/followers' ,
        'permissions':21,
    },
    
]

export default NavDataSearch ;

