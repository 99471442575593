import { faAddressBook, faTachometerAlt, faCircleNotch, faChevronRight, faCompactDisc, faList, faShoppingCart, faSearch, faEdit, faUser, faLock, faImage, faImages, faMusic, faTruckMoving, faVideo, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { render } from "@testing-library/react";
import React  from "react" ;


const NavData = [
    {
        'name':"Dashboard" ,
        'isSubmenu': false ,
        'link':'/panel' ,
        "icon":(<FontAwesomeIcon icon={faTachometerAlt} />),
    },
    {
        'name':"Users" ,
        'isSubmenu': true ,
        "icon":(<FontAwesomeIcon icon={faUser} />),
        'submenu':[
            {
                'name':"List User" ,
                'link':'/panel/userlist' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
                "permissions":5,
            },
            {
                'name':"Add User" ,
                'link':'/panel/useradd' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
                "permissions":6,
            },
        ]
    },
    {
        'name':"Role" ,
        'isSubmenu': true ,
        "icon":(<FontAwesomeIcon icon={faLock} />),
        'submenu':[
            {
                'name':"Add Role" ,
                'link':'/panel/roleadd' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
                "permissions":10,
            },
            {
                'name':"List Role" ,
                'link':'/panel/rolelist' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
                "permissions":9,
            },
        ]
    },
    {
        'name':"Album" ,
        'isSubmenu': true ,
        "icon":(<FontAwesomeIcon icon={faImages} />),
        'submenu':[
            {
                'name':"List Album" ,
                'link':'/panel/albumlist' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
                "permissions":1,
            },
            {
                'name':"Add Album" ,
                'link':'/panel/albumadd' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
                "permissions":2,
            },
        ]
    },
    {
        'name':"Track" ,
        'isSubmenu': true ,
        "icon":(<FontAwesomeIcon icon={faMusic} />),
        'submenu':[
            {
                'name':"List Track" ,
                'link':'/panel/tracklist' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
                "permissions":1,
            },
        ]
    },
    {
        'name':"MusicVideo" ,
        'isSubmenu': true ,
        "icon":(<FontAwesomeIcon icon={faVideo} />),
        'submenu':[
            {
                'name':"All music video" ,
                'link':'/panel/musicvideolist' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
                "permissions":17,

            },
            {
                'name':"Add music video" ,
                'link':'/panel/musicvideoadd' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
                "permissions":18,
            },
        ]
    },
    {
        'name':"Podcasts" ,
        'isSubmenu': true ,
        "icon":(<FontAwesomeIcon icon={faCompactDisc} />),
        'submenu':[
            {
                'name':"All Podcast" ,
                'link':'/panel/podcastlist' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
                "permissions":13,
            },
            {
                'name':"Add Podcast" ,
                'link':'/panel/podcastadd' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
                "permissions":14,
            },
        ]
    },
    {
        'name':"Followers" ,
        'isSubmenu': true ,
        "icon":(<FontAwesomeIcon icon={faUsers} />),
        'submenu':[
            {
                'name':"All Follower" ,
                'link':'/panel/followers' ,
                "icon":(<FontAwesomeIcon icon={faCircleNotch} />),
                "permissions":21,
            },
        ]
    },
]

export default NavData ;

