import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../../widget/Toast_noty'
import Profile from '../../../../static/img/profile.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFileImage, faHeart, faMailBulk, faMoneyBill, faPencilAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import CModal from "../../../../widget/CModal/CModal";
import { Link , Redirect} from "react-router-dom";
import Loader from '../../../../widget/Loader/Loader';
import ImageMusicVideo from "../../../../widget/ImageMusicVideo";
import moment from "moment";
import ImgCrop from 'antd-img-crop';
import { Upload , Form , Input ,DatePicker} from 'antd';
import {Delete_Music_Video , Update_Cover_Music_Video} from '../../../../widget/API'
import checkAccess from "../../../../widget/checkAccess";
import Placeholder from '../../../../static/img/placeholder.png'
import {Image} from 'antd'
import {BASE_Medai_URL} from '../../../../widget/BASE_Medai_URL'


function MusicVideoDetailHead(props) {
    const[dispaly , setDisplay]=useState(false);
    const[redirect , setRedirect]=useState(false);
    const[visibleDelete , setVisibleDelete]=useState(false);
    const [fileList, setFileList] = useState([]);


    // Function For Upload Handel

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
      };
    
    const onPreview = async file => {
        let src = file.url;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
      };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      };

    // End Function For Upload 


    const uploadCoverMusicVideo=()=>{
        if(fileList.length > 0){
            setDisplay(true);
            const returndata =  Update_Cover_Music_Video(props.musicVideo.id, fileList);
            returndata.then(async(res)=>{
            setDisplay(false);
            if(res.result == 'ok'){
                Toast_noty("Changes saved successfully", 10000, "success");
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
            returndata.catch((er)=>{
                setDisplay(false);
                Toast_noty('please try again', 5000, "error");
            })
        }
    }


    const onFinishDeleteMusicVideo=()=>{
        const returndata =  Delete_Music_Video(props.musicVideo.id );
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setVisibleDelete(false)
            setRedirect(true)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
            returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
    }

    return (
              <div className="musicvideodetail-head">
                    {dispaly && <Loader/>}
                    {redirect && <Redirect to='/panel/musicvideolist' />}
                    <CModal visible={visibleDelete} setVisible={setVisibleDelete} title="Delete"> 
                        <p>Are you sure delete album ?</p>
                        <div className="CModel-Bottom">
                            <button className="CModel-Bottom-btn-ok" onClick={onFinishDeleteMusicVideo} >Ok</button>
                            <button className="CModel-Bottom-btn-cancel" onClick={()=>{setVisibleDelete(false)}}>Cansel</button>
                        </div>
                    </CModal>
                  <div className="musicvideodetail-head-right">
                      <div className="musicvideodetail-head-right-profile">
                          <div className="musicvideodetail-head-right-profile-crop">
                            <ImgCrop>
                                <Upload
                                className="mt-2"
                                accept=".png , .jpg , .jpeg , .pdf "
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={onPreview}
                                customRequest={dummyRequest}
                                showUploadList={{showPreviewIcon:false}}
                                >
                                    {fileList.length < 1 &&
                                        <Image 
                                            src={`${BASE_Medai_URL}/storage/cover/artist/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/music_video/${props.musicVideo.id}`}
                                            fallback = {Placeholder}
                                            preview={false}
                                        />
                                    }
                                </Upload>
                            </ImgCrop>
                          </div>
                          
                          <div className="musicvideodetail-head-right-profile-info" >
                              <h3>{props.musicVideo.name} </h3>
                              <span>{moment(props.musicVideo.release_time).format('YYYY-MM-DD hh:mm')}</span>
                              <div>
                                    {checkAccess(19) &&
                                        <>
                                            {fileList.length > 0 && 
                                                <button onClick={uploadCoverMusicVideo} className="musicvideodetail-head-right-profile-div-edit-btn">Upload</button>
                                            }
                                        </>
                                    }
                                    {checkAccess(20) && 
                                        <button onClick={()=>{setVisibleDelete(true)}} className="musicvideodetail-head-right-profile-div-del-btn">Delete</button>
                                    }
                              </div>
                          </div>
                      </div>
                      <div className="musicvideodetail-head-right-bottom">
                          <div className="musicvideodetail-head-right-bottom-item">
                              <div className="musicvideodetail-head-right-bottom-item-icon">
                                  <FontAwesomeIcon icon={faHeart} />
                              </div>
                              <div className="musicvideodetail-head-right-bottom-item-des">
                                  <h5>{props.musicVideo.likes_count}</h5>
                                  <span>likes count</span>
                              </div>
                          </div>
                          {/* <div className="musicvideodetail-head-right-bottom-item">
                              <div className="musicvideodetail-head-right-bottom-item-icon">
                                  <FontAwesomeIcon icon={faHeart} />
                              </div>
                              <div className="musicvideodetail-head-right-bottom-item-des">
                                  <h5>{props.musicVideo.fake_like} </h5>
                                  <span>fake like</span>
                              </div>
                          </div> */}
                      </div>
                  </div>
                  <div className="musicvideodetail-head-left">
                  <div className="musicvideodetail-head-left-item">
                        {checkAccess(19) &&
                          <Link to={`/panel/musicvideoedit/${props.musicVideo.id}`}>
                            <div className="musicvideodetail-head-left-item-left" >
                                <FontAwesomeIcon icon={faEdit} />
                                <span>Edit </span>
                            </div>
                          </Link>
                        }  
                          {/* <span>{props.musicvideo.creator.name}</span> */}
                      </div>
                      <div className="musicvideodetail-head-left-item">
                          <div className="musicvideodetail-head-left-item-left">
                              <FontAwesomeIcon icon={faHeart} />
                              <span>Creator </span>
                          </div>
                          <span>{props.musicVideo.creator.name}</span>
                      </div>
                      <div className="musicvideodetail-head-left-item">
                          <div className="musicvideodetail-head-left-item-left">
                              <FontAwesomeIcon icon={faUser} />
                              <span>Cover producer </span>
                          </div>
                          <span>{props.musicVideo.cover_producer}</span>
                      </div>
                      <div className="musicvideodetail-head-left-item">
                          <div className="musicvideodetail-head-left-item-left">
                              <FontAwesomeIcon icon={faUser} />
                              <span>Editor </span>
                          </div>
                          <span>{props.musicVideo.editor}</span>
                      </div>
                      
                      <div className="musicvideodetail-head-left-item">
                          <div className="musicvideodetail-head-left-item-left">
                              <FontAwesomeIcon icon={faHeart} />
                              <span>Status </span>
                          </div>
                          <span>{props.musicVideo.status}</span>
                      </div>

                      {props.musicVideo.status == 'rejected' && 
                        <div className="musicvideodetail-head-left-item">
                            <div className="musicvideodetail-head-left-item-left">
                                <FontAwesomeIcon icon={faPencilAlt} />
                                <span>Reson </span>
                            </div>
                            <span>{props.musicVideo.reject_message}</span>
                        </div>
                      }


                  </div>
              </div>     
    )
}

export default MusicVideoDetailHead;