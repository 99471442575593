import React, { useEffect, useState } from "react" ;
import Profile from '../../static/img/profile.png'
import MotionNation from '../../static/img/logo/m-n-logo.png'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft, faAlignRight, faBell , faMoon, faPowerOff, faSadTear, faSearch, faSun, faUser} from "@fortawesome/free-solid-svg-icons";
import { fontStyle } from "@mui/system";
import NavDataSearch from "../Nav/NavDataSearch";
import {Link} from 'react-router-dom';
import ImageUser from '../../widget/ImageUser'
import { BASE_Medai_URL } from "../BASE_Medai_URL";
import {Image} from 'antd'
import checkAccess from "../checkAccess";

function ContHead(props) {
    const[sunDisplay , setSunDisplay] = useState(true)
    const[userMenu , setUserMenu] = useState(false)
    const[searchResult , setSearchResult] = useState(false)
    const[displaySearchMobile , setDisplaySearchMobile] = useState(false)
    const[searchList , setSearchList]=useState([]);


    const ChangeDisplayUser=()=>{
        if(userMenu){
            setUserMenu(false)
        }
        else {
            setUserMenu(true)
        }
    }

    const displaySearchResultMenu=(e)=>{
        setSearchList(
            NavDataSearch.filter(value1 => {
               return !!(value1.name.toUpperCase().includes(e.target.value.toUpperCase()));
            })
        )
        if(e.target.value.trim().length > 0){
            setSearchResult(true);
        }
        else {
            setSearchResult(false)
        }
    }

    const ChangeDisplaySearchMobile=()=>{
        if(displaySearchMobile){
            setDisplaySearchMobile(false)
        }
        else {
            setDisplaySearchMobile(true)
        }
    }

    const Logout=()=>{
        localStorage.clear();
        window.location.href = "/";
    }


    React.useEffect(() => {
        const ele = document.getElementById('cont-head-right-user');
        window.addEventListener('click', function (e) {
            if (!ele.contains(e.target)) {
                    setUserMenu(false)
            }
        });
    }, [])


    React.useEffect(() => {
        const ele = document.getElementById('cont-head-left');
        window.addEventListener('click', function (e) {
            if (!ele.contains(e.target)) {
                setSearchResult(false)
            }
        });
    }, [])


    React.useEffect(() => {
        const ele = document.getElementById('cont-head-MobileSearch');
        const eleBtn = document.getElementById('cont-head-left-search-btn-icon');
        window.addEventListener('click', function (e) {
            if (!ele.contains(e.target)) {
                if (!eleBtn.contains(e.target)) {
                    setDisplaySearchMobile(false)
               }
            }
        });
    }, [])


    return (
        <div className="cont-head">
            <div id="cont-head-left" className="cont-head-left">
                <div className="cont-head-left-align">
                    <FontAwesomeIcon onClick={props.setDisplayNavMobile} icon={faAlignLeft} />
                    <img src={MotionNation} />
                </div>
                
                <form className="cont-head-left-align-form-search">
                    <button><FontAwesomeIcon icon={faSearch} /></button>
                    <input type='text' onChange={(e)=>displaySearchResultMenu(e)} />
                    <div className={`cont-head-left-align-search-result ${searchResult && 'cont-head-left-align-search-result-active'}`}>
                        {searchList.length > 0 ?
                            <>
                                {searchList.map(item=>
                                    <Link to={item.link}>
                                        <div>
                                            <FontAwesomeIcon icon={faSearch} />
                                            <span>{item.name}</span>
                                        </div>
                                    </Link>
                                )}
                            </>
                        :
                            <div>
                                <FontAwesomeIcon icon={faSadTear} />
                                <span>Not found</span>
                            </div>
                        }
                    </div>
                </form>
                <div className="cont-head-left-search-btn" >
                    <FontAwesomeIcon id="cont-head-left-search-btn-icon" icon={faSearch} onClick={()=>ChangeDisplaySearchMobile()} />
                </div>
            </div>
            <div className="cont-head-right">
                {/* <div className="cont-head-right-icon">
                    {sunDisplay ? 
                    <FontAwesomeIcon onClick={()=>{setSunDisplay(true)}} icon={faSun} />
                    :
                    <FontAwesomeIcon onClick={()=>{setSunDisplay(false)}} icon={faMoon} />
                    }
                </div> */}
                {/* <div className="cont-head-right-icon">
                    <FontAwesomeIcon icon={faBell} />
                </div> */}
                <div className="cont-head-right-user" id="cont-head-right-user" >
                    <div className="cont-head-right-user-name" onClick={()=>ChangeDisplayUser()}>
                        <h3>{JSON.parse(localStorage.getItem('userInfo')).email}</h3>
                        {/* <img src={Profile} alt='profile' /> */}
                        {JSON.parse(localStorage.getItem('userInfo')).role_id == -1 ? 
                            <ImageUser coverId={JSON.parse(localStorage.getItem('userInfo')).id} />
                        :
                            <Image 
                                src={`${BASE_Medai_URL}/storage/avatar/artist/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/user/${JSON.parse(localStorage.getItem('userInfo')).id}`}
                                fallback = {Profile}
                                preview={false}
                            />
                        }
                    </div>
                    <div className={`cont-head-right-user-info ${userMenu && 'cont-head-right-user-info-active'}`}>
                        <h3>{JSON.parse(localStorage.getItem('userInfo')).email}</h3>
                        <Link to={`/panel/useredit/${JSON.parse(localStorage.getItem('userInfo')).id}`}>
                            <div className="cont-head-right-user-info-item">
                                <FontAwesomeIcon icon={faUser} />
                                <span>Edit Profile</span>
                            </div>
                        </Link>
                        {checkAccess(22) && 
                            <Link to={`/panel/userartistedit/`}>
                                <div className="cont-head-right-user-info-item">
                                    <FontAwesomeIcon icon={faUser} />
                                    <span>Edit Artist</span>
                                </div>
                            </Link>
                        }
                        <div onClick={Logout} className="cont-head-right-user-info-item">
                            <FontAwesomeIcon icon={faPowerOff} />
                            <span>Logout </span>
                        </div>
                        <div className="cont-head-right-user-info-bottom">
                            <div className="cont-head-right-user-info-bottom-item">
                                {sunDisplay ? 
                                    <FontAwesomeIcon onClick={()=>{setSunDisplay(true)}} icon={faSun} />
                                :
                                    <FontAwesomeIcon onClick={()=>{setSunDisplay(false)}} icon={faMoon} />
                                }
                            </div>
                            <div className="cont-head-right-user-info-bottom-item">
                                <FontAwesomeIcon icon={faBell} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div id="cont-head-MobileSearch" className={`cont-head-MobileSearch ${displaySearchMobile && 'cont-head-MobileSearch-active'}`}>
                <form >
                    <button><FontAwesomeIcon icon={faSearch} /></button>
                    <input type='text' onChange={(e)=>displaySearchResultMenu(e)} />
                    <div className={`cont-head-left-align-search-result ${searchResult && 'cont-head-left-align-search-result-active'}`}>
                        {searchList.length > 0 ?
                            <>
                                {searchList.map(item=>
                                    <Link to={item.link}>
                                        <div>
                                            <FontAwesomeIcon icon={faSearch} />
                                            <span>{item.name}</span>
                                        </div>
                                    </Link>
                                )}
                            </>
                        :
                            <div>
                                <FontAwesomeIcon icon={faSadTear} />
                                <span>Not found</span>
                            </div>
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ContHead;