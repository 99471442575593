import React, { useState } from "react" ;
import './skeletonStyle.css';


function SkeletonSection(props) {



    return (
        <>
        <div className="skeleton-section">
            <div className="skeleton-section-square"></div>
            <div className="skeleton-section-line">
                <div className="skeleton-section-line-item"/>
                <div className="skeleton-section-line-item"/>
                <div className="skeleton-section-line-item"/>
            </div>

        </div>

        </>
    )
}

export default SkeletonSection;