import React, { useEffect, useState , useCallback } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../widget/Toast_noty'
import Profile from '../../../static/img/profile.png'
import { Update_Artist  , Update_Cover_Artist} from '../../../widget/API';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCloudUploadAlt, faLock, faTrash , faEye } from "@fortawesome/free-solid-svg-icons";
import Cropper from 'react-easy-crop'
import getCroppedImg from '../../../widget/cropImage'
import CModal from '../../../widget/CModal/CModal';
import Loader from "../../../widget/Loader/Loader";
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import ImageUser from '../../../widget/ImageUser'
import '../userStyle.css';
import { BASE_Medai_URL } from "../../../widget/BASE_Medai_URL";


function UserArtistEdit(props) {
    const[dispaly , setDisplay]=useState(false);
    const[listRoles , setlistRoles]=useState([]);
    const [name , setName]=useState('');
    const [about , setAbout]=useState('');

    const [fileList, setFileList] = useState([]);


    // Function For Upload Handel

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
      };
    
    const onPreview = async file => {
        let src = file.url;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
      };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      };

    // End Function For Upload 


    const uploadImage=()=>{
        if(fileList.length > 0){
            setDisplay(true);
            const returndata =  Update_Cover_Artist(fileList);
            returndata.then(async(res)=>{
            setDisplay(false);
            if(res.result == 'ok'){
                Toast_noty("Changes saved successfully", 10000, "success");
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
            returndata.catch((er)=>{
                setDisplay(false);
                Toast_noty('please try again', 5000, "error");
            })
        }
    }



    const finishEdit=(e)=>{
        e.preventDefault();
        setDisplay(true);
        const returndata =  Update_Artist( name , about);
        returndata.then(async(res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            let userinfo = JSON.parse(localStorage.getItem('userInfo')) ;
            userinfo.artistName = name ;
            userinfo.artistAbout = about ;
            localStorage.setItem('userInfo' , JSON.stringify(userinfo))
            await getData();
            Toast_noty("Changes saved successfully", 10000, "success");
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }

    const getData=()=>{
        setName(JSON.parse(localStorage.getItem('userInfo')).artistName)
        setAbout(JSON.parse(localStorage.getItem('userInfo')).artistAbout)
    }

    useEffect(()=>{
        getData()
    },[])


    return (
        <div className={`fullPage` }>
          {dispaly && <Loader/>}  
          <div className="useradd">
              <div className="useradd-head">
                  <div className="useradd-head-edit-pro">
                        <ImgCrop>
                            <Upload
                            className="mt-2"
                            accept=".png , .jpg , .jpeg , .pdf "
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={onPreview}
                            customRequest={dummyRequest}
                            showUploadList={{showPreviewIcon:false}}
                            >
                                {fileList.length < 1 &&
                                    // <ImageUser coverId={JSON.parse(localStorage.getItem('userInfo')).artist_id} />
                                    <img src={`${BASE_Medai_URL}/storage/avatar/artist/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/profile`} />
                                }
                            </Upload>
                        </ImgCrop>
                  </div>
                  <div className="useradd-head-edit-detail">
                      <h3>{name} </h3>
                      {fileList.length > 0 && 
                        <button onClick={uploadImage}>Upload</button>
                       }
                  </div>
              </div>
              <form onSubmit={finishEdit} className="useradd-form">

                <div className="useradd-form-one">
                    <div className="useradd-form-one-item">
                        <span>Name</span>
                        <input type="name" value={name} onChange={(e)=>{setName(e.target.value)}} />
                    </div>
                    <div className="useradd-form-one-item">
                        <span>About</span>
                        <input type="text" value={about} onChange={(e)=>{setAbout(e.target.value)}} />
                    </div>
                </div>  

                <div className="useradd-form-bottom">
                    <button>Save change</button>
                </div>
                
              </form>
          </div>
        </div>
    )
}

export default UserArtistEdit;