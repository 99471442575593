import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faSearch, faTable , faStar, faHeart, faMusic, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import AlbumAddStepOne from './widget/AlbumAddStepOne';
import AlbumAddStepTwo from './widget/AlbumAddStepTwo';
import AlbumAddStepThree from './widget/AlbumAddStepThree';
import '../albumStyle.css'





function AlbumAdd(props) {
    const[dispaly , setDisplay]=useState(false);
    const[step , setStep]=useState(1);
    const[albumId , setAlbumId]=useState(0);
    const[albumName , setAlbumName]=useState(0);
    const[coverFile , setCoverFile]=useState(null);
    const[albumPay , setAlbumPay]=useState(false);





 


    return (
        <div className="addalbum">
            {dispaly && <Loader/>}
            <div className="addalbum-head">
                <div className={`addalbum-head-item ${step == 1 && 'addalbum-head-item-active'}`}>
                    <div className="addalbum-head-item-cont">1</div>
                    <div className="addalbum-head-item-des">
                        <span>Add Album</span>
                        {/* <span>Detail album info</span> */}
                    </div>
                    <div className="addalbum-head-item-end"><FontAwesomeIcon icon={faChevronRight} /></div>
                </div>
                <div className={`addalbum-head-item ${step == 2 && 'addalbum-head-item-active'}`}>
                    <div className="addalbum-head-item-cont">2</div>
                    <div className="addalbum-head-item-des">
                        <span>Add Cover</span>
                        {/* <span>Detail album info</span> */}
                    </div>
                    <div className="addalbum-head-item-end"><FontAwesomeIcon icon={faChevronRight} /></div>
                </div>
                <div className={`addalbum-head-item ${step == 3 && 'addalbum-head-item-active'}`}>
                    <div className="addalbum-head-item-cont">3</div>
                    <div className="addalbum-head-item-des">
                        <span>Add Track</span>
                        {/* <span>Detail album info</span> */}
                    </div>
                </div>
            </div>
            {step == 1 && <AlbumAddStepOne setStep={setStep} setAlbumId={setAlbumId} setAlbumName={setAlbumName} albumPay={albumPay} setAlbumPay={setAlbumPay} /> }
            {step == 2 && <AlbumAddStepTwo setStep={setStep} albumId={albumId} setCoverFile={setCoverFile}   /> }
            {step == 3 && <AlbumAddStepThree setStep={setStep} albumId={albumId} coverFile={coverFile} albumPay={albumPay}   /> }
        </div>
    )
}

export default AlbumAdd;