import React, { useEffect, useRef, useState } from "react" ;
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faEllipsisV , faTrash , faEdit} from "@fortawesome/free-solid-svg-icons";
import {Get_Track} from '../../../widget/API';
import { useLocation } from 'react-router-dom'
import { cssTransition } from "react-toastify";
import {Link} from 'react-router-dom';
import Table from "../../../widget/Table/Table";
import TrackDetailComment from './widget/TrackDetailComment'
import TrackDetailLike from './widget/TrackDetailLike'
import TrackDetailViews from './widget/TrackDetailViews'


function TrackDetail(props) {
    const[dispaly , setDisplay]=useState(false);
    const[track , setTrack]=useState(null);





    // useEffect(()=>{
    //     if(props.match.params.id){
    //         const returndata =  Get_Track(props.match.params.id);
    //         returndata.then((res)=>{
    //         if(res.result == 'ok'){
    //             setTrack(res.data)
    //         }
    //         else if(res.error == 'unauthenticated') {
    //             localStorage.clear();
    //             props.history.push('/');  
    //         }
    //         else {
    //             Toast_noty(res.error, 5000, "error");
    //         }
    //         })
    //          returndata.catch((er)=>{
    //             Toast_noty('please try again', 5000, "error");
    //         })
    //     }
    //   },[])

    return (
        <>
            <TrackDetailComment albumId={props.match.params.albumId} trackId={props.match.params.trackId} />
            <TrackDetailLike albumId={props.match.params.albumId} trackId={props.match.params.trackId} />
            <TrackDetailViews albumId={props.match.params.albumId} trackId={props.match.params.trackId} />

        </>
    )
}

export default TrackDetail;