import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../../widget/Toast_noty'
import Profile from '../../../../static/img/profile.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFileImage, faMailBulk, faMoneyBill, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";

function UserDetailMiddel(props) {
    const[listPermission , setListPermission]=useState([])

    const checkPermission=(id)=>{
        if(listPermission.length > 0){
            for(let i of listPermission){
                if(i.id == id){
                    return true
                }
            }
            return false
        }
    }


    useEffect(()=>{
        if(props.detailUser && props.detailUser.role != null){
            setListPermission(props.detailUser.role.permissions)

        }

    },[props.detailUser])

    return (
              <div className="userdetail-middel">
                  <div className="userdetail-middel-left">
                      <div className="userdetail-middel-left-head">
                          <h3>User Timeline</h3>
                      </div>
                      <div className="userdetail-middel-left-item">
                        <div className="userdetail-middel-left-item-des">
                            <h4>12 Invoices have been paid</h4>
                            <span>Add files to new design folder</span>
                        </div>
                        <div className="userdetail-middel-left-item-time">
                            2020-03-11
                        </div>
                      </div>
                  </div>
                  <div className="userdetail-middel-right">
                      <div className="userdetail-middel-right-head">
                          <h3>Permissions</h3>
                      </div>
                    {listPermission.length > 0 && 
                      <div className="userdetail-middel-right-cont">
                          <div className="userdetail-middel-right-cont-item">
                              <div className="userdetail-middel-right-cont-item-sec">Module</div>
                              <div className="userdetail-middel-right-cont-item-sec">See</div>
                              <div className="userdetail-middel-right-cont-item-sec">Add</div>
                              <div className="userdetail-middel-right-cont-item-sec">Update</div>
                              <div className="userdetail-middel-right-cont-item-sec">Delete</div>
                          </div>

                          <div className="userdetail-middel-right-cont-item">
                              <div className="userdetail-middel-right-cont-item-sec">Album & Track</div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(1) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(2) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(3) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(4) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                          </div>

                          <div className="userdetail-middel-right-cont-item">
                              <div className="userdetail-middel-right-cont-item-sec">Users</div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(5) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(6) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(7) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(8) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                          </div>

                          <div className="userdetail-middel-right-cont-item">
                              <div className="userdetail-middel-right-cont-item-sec">Roles</div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(9) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(10) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(11) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(12) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                          </div>

                          <div className="userdetail-middel-right-cont-item">
                              <div className="userdetail-middel-right-cont-item-sec">Roles</div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(9) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(10) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(11) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(12) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                          </div>

                          <div className="userdetail-middel-right-cont-item">
                              <div className="userdetail-middel-right-cont-item-sec">Podcast</div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(13) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(14) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(15) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(16) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                          </div>

                          <div className="userdetail-middel-right-cont-item">
                              <div className="userdetail-middel-right-cont-item-sec">Music Video</div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(17) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(18) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(19) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(20) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                          </div>

                          <div className="userdetail-middel-right-cont-item">
                              <div className="userdetail-middel-right-cont-item-sec">Follower</div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(21) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  
                              </div>
                          </div>

                          <div className="userdetail-middel-right-cont-item">
                              <div className="userdetail-middel-right-cont-item-sec">Artists</div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  <span className={`userdetail-middel-right-cont-item-sec-check ${checkPermission(22) && 'userdetail-middel-right-cont-item-sec-check-active'}`}>
                                      <FontAwesomeIcon icon={faCheck} />
                                  </span>
                              </div>
                              <div className="userdetail-middel-right-cont-item-sec">
                                  
                              </div>
                          </div>

                      </div>
                    }
                  </div>

              </div>     
    )
}

export default UserDetailMiddel;