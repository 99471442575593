import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../widget/Toast_noty'
import Profile from '../../../static/img/profile.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faEllipsisV, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import UserDetailHead from './widget/UserDetailHead';
import UserDetailMiddel from './widget/UserDetailMiddel'
import UserDetailAlbum from './widget/UserDetailAlbum'
import {Get_Single_Users, Get_Users} from '../../../widget/API'
import {Link} from 'react-router-dom';
import Table from "../../../widget/Table/Table";
import '../userStyle.css';



function UserDetail(props) {
    const[detailUser , setDetailUser]=useState('');
    const[actionList , setActionList]=useState(-1);
    const[range , setRange]=useState(3);

    const changeDisplayActionList=(id)=>{
        if(actionList == id){
            setActionList(-1)
        }
        else {
            setActionList(id)
        }
    }

    const col = [
        {
            title:"Name" ,
            render:(i) => (
                <div className="table-profile">
                    <div>{i.name != null && i.name.substr(0, 1)}</div>
                    <span>{i.name != null && i.name}</span>
                </div>)
        },
        {
            title:"Email" ,
            render:(i) => (<Link to={`/panel/user/${i.id}`}>{i.email != null && i.email}</Link>)

        },
        {
            title:"Role" ,
            render:(i) => (<span>{i.role != null && i.role.name}</span>)
        },
        {
            title:"Action" ,
            render:(i) => (
            <div id="table-action" className="table-action">
                <FontAwesomeIcon icon={faEllipsisV} onClick={()=>changeDisplayActionList(i.id)} />
                <div  className={`table-action-list ${actionList == i.id && 'table-action-list-active'}`}>
                    <div className="table-action-list-item">
                        <FontAwesomeIcon icon={faTrash} />
                        <span>Delete</span>
                    </div>
                    <div className="table-action-list-item">
                        <FontAwesomeIcon icon={faEdit} />
                        <span>Edit</span>
                    </div>
                </div>
            </div>
            )
        },
    ]



    useEffect(()=>{
        const returndata =  Get_Single_Users(props.match.params.id);
        returndata.then((res)=>{
        if(res.result == 'ok'){
            setDetailUser(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
      },[])





    return (
          
        <div className={`userdetail `}>
            <UserDetailHead detailUser={detailUser} />
            <UserDetailMiddel detailUser={detailUser} />
            <UserDetailAlbum detailUser={detailUser} />

            
        </div>

           
    )
}

export default UserDetail;