import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Select , DatePicker, Space , Upload , Form , Input, InputNumber, Button} from 'antd';
import AudioPlayer from 'react-h5-audio-player';
import ImgCrop from 'antd-img-crop';
import {Get_All_Artists , Add_Music_Video} from '../../../widget/API';
import moment from "moment";
import { Redirect } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import MusicVideoAutoFields from '../MusicVideoAutoFields';
import '../musicvideoStyle.css';



function MusicVideoAdd(props) {
    const[dispaly , setDisplay]=useState(false);
    const[redirect , setRedirect]=useState(false);
    const[fileList , setFileList]=useState([]);
    const[fileMusic , setFileMusic]=useState(null);
    const[feats , setFeats]=useState(false);
    const[pay , setPay]=useState(false);
    const[listArtists , setListArtists]=useState([]);

    const { Option } = Select;




    // Function For Upload Handel

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        };
    
    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
        };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
        };

    // End Function For Upload 



    const onFinish=(values)=>{
        if(fileList.length != 0 && fileMusic != null){
            if(values && values.labels){
                values['labels'] =  Object.entries(values.labels).map(([key , value])=>{
                    if(value && value._d){
                        return {
                            "id" : key,
                            "value" : String(moment(value._d).format('YYYY-MM-DD hh:mm')),
                        }
                    }
                    else {
                        return {
                            "id" : key,
                            "value" : String(value),
                        }
                    }
                    
                })
            }
            setDisplay(true);
            const returndata =  Add_Music_Video(values , fileList , fileMusic );
            returndata.then((res)=>{
            setDisplay(false)
            if(res.result == 'ok'){
                setRedirect(true)
            }
            else 
            {
                Toast_noty(res.error, 5000, "error");
            }
            })
            returndata.catch((er)=>{
                setDisplay(false);
                Toast_noty('please try again', 5000, "error");
            })
        }
        else {
            Toast_noty('please select video and cover', 5000, "error");
        }
    }

    const getArtists=()=>{
        const returndata =  Get_All_Artists();
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            setListArtists(res.data)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }
 

    useState(()=>{
        getArtists()
    })


    return (
        <div className="musicvideodetail">
            {dispaly && <Loader/>}
            {redirect && <Redirect to='/panel/musicvideolist' />}
            <div className="addmusicvideo-cont">
                <Form onFinish={onFinish} className="addmusicvideo-cont-form">
                    <div className="addmusicvideo-cont-form-section">

                        <div className="addmusicvideo-cont-form-section-cover-head">
                            <div className="addmusicvideo-cont-form-section-cover-head-inner">
                                <ImgCrop>
                                    <Upload
                                    className="mt-2"
                                    accept=".png , .jpg , .jpeg , .pdf "
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={onChange}
                                    onPreview={onPreview}
                                    customRequest={dummyRequest}
                                    showUploadList={{showPreviewIcon:false}}
                                    >
                                        {fileList.length < 1 &&
                                            <div className="addmusicvideo-cont-form-section-cover-head-inner-upload">
                                                <span>Cover</span>
                                                <FontAwesomeIcon icon={faUpload} />
                                            </div>
                                        }
                                    </Upload>
                                </ImgCrop>
                            </div>
                        </div>

                        <div className="addmusicvideo-cont-form-section-item">
                            <span>Music video name</span>
                            <Form.Item name="name" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <div className="addmusicvideo-cont-form-section-item">
                            <span>Director</span>
                            <Form.Item name="director" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <div className="addmusicvideo-cont-form-section-item">
                            <span>Release time</span>
                            <Form.Item name="release_time" rules={[{ required: true}]}>
                                <DatePicker />
                            </Form.Item>
                        </div>

                        <MusicVideoAutoFields/>

                        {/* <div className="addmusicvideo-cont-form-section-item">
                            <span>Pay status</span>
                            <Form.Item name="pay" rules={[{ required: true}]}>
                                <Select
                                    allowClear
                                    onChange={(value)=>{setPay(value)}}
                                    >
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </div> */}

                        {/* {pay && 
                        <div className="addmusicvideo-cont-form-section-item">
                            <span>Rial price</span>
                            <Form.Item name="Rial_price" rules={[{ required: true}]}>
                                <InputNumber />
                            </Form.Item>
                        </div>
                        } */}

                        {/* {pay && 
                        <div className="addmusicvideo-cont-form-section-item">
                            <span>Dollar price</span>
                            <Form.Item name="Dollar_price" rules={[{ required: true}]}>
                                <InputNumber />
                            </Form.Item>
                        </div>
                        } */}

                        {/* {pay && 
                        <div className="addmusicvideo-cont-form-section-item">
                            <span>Vip</span>
                            <Form.Item name="vip" rules={[{ required: true}]}>
                                <Select
                                    allowClear
                                    >
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </div>
                        } */}

                        <div className="addmusicvideo-cont-form-section-item">
                            <span>Feats</span>
                            <Form.Item name="isFeats" >
                                <Select
                                    allowClear
                                    onChange={(value)=>{setFeats(value)}}
                                    >
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </div>

                        {feats && 
                            <div className="addmusicvideo-cont-form-section-feat">
                                <Form.List name="feats">
                                    {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                            <Form.Item
                                            {...restField}
                                            name={[name, 'artist_id']}
                                            fieldKey={[fieldKey, 'artist_id']}
                                            rules={[{ required: true, }]}
                                            >
                                                <Select
                                                    placeholder="Select artist"
                                                    allowClear
                                                    >
                                                        {listArtists.map(item=>
                                                            <Option value={item.id}>{item.name}</Option>
                                                        )}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                            {...restField}
                                            name={[name, 'percentage']}
                                            fieldKey={[fieldKey, 'percentage']}
                                            rules={[{ required: true}]}
                                            >
                                            <InputNumber placeholder="percentage" />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                        ))}
                                        <Form.Item>
                                        <div className="addmusicvideo-cont-form-section-feat-add-btn"  onClick={() => add()}>
                                            Add feats
                                        </div>
                                        </Form.Item>
                                    </>
                                    )}
                                </Form.List>
                            </div>
                        }


                        {fileMusic == null &&

                            <label className="addmusicvideo-cont-form-section-musicVideo">
                                <input type="file" onChange={(e)=>{setFileMusic(e.target.files[0])}}  accept="video/*" />
                                <div className="addmusicvideo-cont-form-section-musicVideo-des">
                                    <h3>Click here to upload.</h3>
                                    <FontAwesomeIcon icon={faUpload} />
                                </div>
                            </label>
                        }

                        {fileMusic != null &&
                            <>
                            {fileMusic.type == "video/mp4" ? 
                            <div className="addmusicvideo-cont-form-section-movies-prev">
                                <video  controls>
                                    <source src={URL.createObjectURL(fileMusic)}  />
                                </video> 
                            </div>
                            :
                            <label className="addmusicvideo-cont-form-section-musicVideo">
                                <div className="addmusicvideo-cont-form-section-musicVideo-des">
                                    <h3>{fileMusic.name}</h3>
                                </div>
                            </label>
                            }
                            </>
                        }
                        
                    </div>
                    <div className="addmusicvideo-cont-form-footer">
                        <button type="submit" className="addmusicvideo-cont-form-footer-btn-next">Add</button>
                        {fileMusic != null &&
                            <button onClick={()=>{setFileMusic(null)}} type="button" className="addmusicvideo-cont-form-footer-btn-del">Delete</button>
                        }
                    </div>

                </Form>
            </div>
        </div>
    )
}

export default MusicVideoAdd;