import React, { useState , useEffect } from "react" ;
import { faMailBulk, faUser , faEye, faEyeSlash, faSign, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { faTelegram, faWhatsapp , faFacebook , faInstagram , faTwitter, faGoogle, faApple  } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from '../static/img/logo/m-n-logo.png'
import {LoginAuth} from '../widget/API';
import LoaderBtn from '../widget/Loader/LoaderBtn';
import {ToastContainer} from "react-toastify";
import Toast_noty from '../widget/Toast_noty';
import { Link } from "react-router-dom";
import './authStyle.css'


function LoginFromAdmin(props) {






    useEffect(()=>{
        const params = new URLSearchParams(window.location.search)
        if(params.get('name') && params.get('token') && params.get('email') && params.get('id')){
            let userData = {
                "id":params.get('id') ,
                "artist_id":params.get('id') ,
                "email":params.get('email') ,
                "name":"admin",
                "name_user":"admin",
                'token':params.get('token') ,
                'artistName':params.get('name') ,
                'artistAbout':"about this artist" ,
                'role_id':-1 ,
                'permissions': [] ,
            }
            localStorage.setItem('userInfo' , JSON.stringify(userData))
            props.history.push('/panel');  
        }

    },[])


    return (
        <div className="auth" >
          
        </div>
    )
}

export default LoginFromAdmin;