import React, { useEffect, useRef, useState } from "react" ;
import Toast_noty from '../../../../widget/Toast_noty'
import Loader from "../../../../widget/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faEllipsisV , faChevronLeft , faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {Get_Track_Comments} from '../../../../widget/API';
import { useLocation } from 'react-router-dom'
import { cssTransition } from "react-toastify";
import {Link} from 'react-router-dom';
import TablePage from "../../../../widget/Table/TablePage";




function TrackDetailComment(props) {
    const[dispaly , setDisplay]=useState(false);
    const[listComment , setListComment]=useState([]);
    const [currentPage , setCurrentPage]=useState(1);
    const [lastPage , setLastPage]=useState(0);


    const changePage=(status)=>{
        if(status){
            if(lastPage >= currentPage + 1){
                getData(currentPage + 1)
            }
        }
        else {
            if(currentPage > 1){
                getData(currentPage - 1)
            }
        }
    }


    const colLike = [
        {
            title:"Body" ,
            render:(i) => (<>{i.body != null && i.body}</>)
        },
        // {
        //     title:"Email" ,
        //     render:(i) => (<>{i.email != null && i.email}</>)
        // },
    ]


    const getData=(current)=>{
        const returndata =  Get_Track_Comments(props.albumId , props.trackId , current);
        returndata.then((res)=>{
        if(res.result == 'ok'){
            setListComment(res.data.data)
            setCurrentPage(res.data.current_page)
            setLastPage(res.data.last_page)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
    }


    useEffect(()=>{
        if(props.albumId && props.trackId){
            getData(currentPage)
        }
      },[props.albumId])

    return (
        <div className="fullPage">
            <div className="pagelist">
                <div className="pagelist-head">
                    <span>Track comment</span>
                </div>
                <TablePage columns={colLike} data={listComment}>
                    {currentPage != 1 && 
                        <div className="table-counter-main-item" onClick={()=>changePage(false)} ><FontAwesomeIcon icon={faChevronLeft} /></div>
                    }
                        <div className="table-counter-main-item">{currentPage} </div>
                    {lastPage >= currentPage + 1 && 
                        <div className="table-counter-main-item" onClick={()=>changePage(true)} ><FontAwesomeIcon icon={faChevronRight} /></div>
                    }
                </TablePage>
            </div>
        </div> 
    )
}

export default TrackDetailComment;