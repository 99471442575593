import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../../widget/Toast_noty'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFileImage, faHeart, faMailBulk, faMoneyBill, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import FileMusicVideo from '../../../../widget/FileMusicVideo'
import CModal from "../../../../widget/CModal/CModal";
import Loader from '../../../../widget/Loader/Loader';
import {Update_Podcast , Play_Podcast} from '../../../../widget/API'
import AudioPlayer from 'react-h5-audio-player';
import ImagePodcast from '../../../../widget/ImagePodcast'
import checkAccess from "../../../../widget/checkAccess";
import Placeholder from '../../../../static/img/placeholder.png'
import {Image} from 'antd'
import {BASE_Medai_URL} from '../../../../widget/BASE_Medai_URL'



function PodcastDetailCont(props) {
    const [selectFileForUpdatePodcast , setSelectFileForUpdatePodcast]=useState(null)
    const[visibleUpdatePodcast , setVisibleUpdatePodcast]=useState(false);
    const[dispaly , setDisplay]=useState(false);
    const[filePodcast , setFilePodcast]=useState(null)


    const onFinishUpdatePodcast=()=>{
        setDisplay(true)
        const returndata =  Update_Podcast(props.podcast.id , selectFileForUpdatePodcast);
        returndata.then((res)=>{
        setDisplay(false)
        if(res.result == 'ok'){
            props.getDetailPodcast();
            setVisibleUpdatePodcast(false);
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
            returndata.catch((er)=>{
            setDisplay(false)
            Toast_noty('please try again', 5000, "error");
        })
    }


    const playPodcast=()=>{
        const returndata =  Play_Podcast(props.podcast.id);
            returndata.then((res)=>{
                setFilePodcast(res)
            })
    }

    const emptyFields=()=>{
        setSelectFileForUpdatePodcast(null)
    }

    useEffect(()=>{
        if(props.podcast){
            playPodcast()
        }
    },[props.podcast])

    return (
        <>
            
            <CModal onScap={emptyFields} visible={visibleUpdatePodcast} setVisible={setVisibleUpdatePodcast} title="Update podcast"> 
                <div className="podcastdetail-update-modal">
                    <label>
                        <input accept=".mp3"  onChange={(e)=>setSelectFileForUpdatePodcast(e.target.files[0])} type="file" required />
                        {selectFileForUpdatePodcast == null ? 
                            <span>+ Select podcast file</span>
                        :
                            <span>{selectFileForUpdatePodcast.name}</span>
                        }
                    </label>
                    {selectFileForUpdatePodcast != null && 
                        <button className="podcas-modal-player-btn" onClick={onFinishUpdatePodcast} >Update</button>
                    }
                </div>
            </CModal>
            {dispaly && <Loader />}
            <div className="fullPage">   
            {props.podcast != null && 
            <div className="pagelist">
                <div className="pagelist-head">
                    <span>Podcast</span>
                </div>
                <div className="podcastdetail-prev-video">
                    <div className="addpodcast-cont-form-section-item-file-player-parent">
                        <div className="addpodcast-cont-form-section-item-file-player">
                            <div className="addpodcast-cont-form-section-item-file-player-cover">
                                {/* <img src={URL.createObjectURL(props.coverFile[0].originFileObj)} alt="cover" /> */}
                                <Image 
                                    src={`${BASE_Medai_URL}/storage/cover/artist/${JSON.parse(localStorage.getItem('userInfo')).artist_id}/podcast/${props.podcast.id}`}
                                    fallback = {Placeholder}
                                    preview={false}
                                />
                            </div>
                            <div className="addpodcast-cont-form-section-item-file-player-main">
                                {filePodcast != null && 
                                    <AudioPlayer   
                                        src={filePodcast}
                                    />
                                }
                            </div>
                        </div>
                    </div>  
                    <div className="addpodcast-cont-form-footer">
                        {checkAccess(16) && 
                            <button onClick={()=>{setVisibleUpdatePodcast(true)}} className="addpodcast-cont-form-footer-btn-next">Change Podcast</button>
                        }
                    </div>
                </div>
            </div>
            }
            </div>  
        </>
    )
}

export default PodcastDetailCont;