import React, { useEffect, useState } from "react" ;
import {ToastContainer} from "react-toastify";
import Toast_noty from '../../../../widget/Toast_noty'
import Profile from '../../../../static/img/profile.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage, faMailBulk, faMoneyBill, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import CModal from "../../../../widget/CModal/CModal";
import { Link , Redirect} from "react-router-dom";
import {Delete_User } from '../../../../widget/API';
import Loader from '../../../../widget/Loader/Loader';
import ImageUser from '../../../../widget/ImageUser'
import checkAccess from "../../../../widget/checkAccess";


function UserDetailHead(props) {
    const[dispaly , setDisplay]=useState(false);
    const[redirect , setRedirect]=useState(false);
    const[visibleDelete , setVisibleDelete]=useState(false);


    const deleteUser=async()=>{
        setDisplay(true);
        setVisibleDelete(false)
        const returndata =  Delete_User(props.userId);
        returndata.then(async(res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            // props.history.push('/panel');
            setRedirect(true)  
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })
    }



    return (
              <div className="userdetail-head">
                    {dispaly && <Loader/>}
                    {redirect && <Redirect to='/panel/userlist' />}
                    <CModal visible={visibleDelete} setVisible={setVisibleDelete} title="Delete"> 
                        <p>Are you sure delete user {props.userId} ?</p>
                        <div className="CModel-Bottom">
                            <button className="CModel-Bottom-btn-ok" onClick={deleteUser}>Ok</button>
                            <button className="CModel-Bottom-btn-cancel" onClick={()=>{setVisibleDelete(false)}}>Cansel</button>
                        </div>
                    </CModal>
                  <div className="userdetail-head-right">
                      <div className="userdetail-head-right-profile">
                          {/* <img src={Profile} alt="profile" /> */}
                          <ImageUser coverId={props.detailUser.id} />
                          <div >
                              <h3>{props.detailUser.name}</h3>
                              <span>{props.detailUser.email}</span>
                              <div>
                                  {checkAccess(7) && 
                                    <Link to={`/panel/useredit/${props.detailUser.id}`}>
                                        <button className="userdetail-head-right-profile-div-edit-btn">Edit</button>
                                    </Link>
                                  }
                                  {checkAccess(8) && 
                                    <button onClick={()=>{setVisibleDelete(true)}} className="userdetail-head-right-profile-div-del-btn">Delete</button>
                                  }
                              </div>
                          </div>
                      </div>
                      <div className="userdetail-head-right-bottom">
                          <div className="userdetail-head-right-bottom-item">
                              <div className="userdetail-head-right-bottom-item-icon">
                                  <FontAwesomeIcon icon={faMoneyBill} />
                              </div>
                              <div className="userdetail-head-right-bottom-item-des">
                                  <h5>23.3k </h5>
                                  <span>Monthly Sales</span>
                              </div>
                          </div>
                          <div className="userdetail-head-right-bottom-item">
                              <div className="userdetail-head-right-bottom-item-icon">
                                  <FontAwesomeIcon icon={faMoneyBill} />
                              </div>
                              <div className="userdetail-head-right-bottom-item-des">
                                  <h5>23.3k </h5>
                                  <span>Monthly Sales</span>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="userdetail-head-left">
                      <div className="userdetail-head-left-item">
                          <div className="userdetail-head-left-item-left">
                              <FontAwesomeIcon icon={faUser} />
                              <span>Name </span>
                          </div>
                          <span>{props.detailUser.name}</span>
                      </div>
                      <div className="userdetail-head-left-item">
                          <div className="userdetail-head-left-item-left">
                              <FontAwesomeIcon icon={faMailBulk} />
                              <span>Email </span>
                          </div>
                          <span>{props.detailUser.email}</span>
                      </div>
                      <div className="userdetail-head-left-item">
                          <div className="userdetail-head-left-item-left">
                              <FontAwesomeIcon icon={faPhone} />
                              <span>phone </span>
                          </div>
                          <span>{props.detailUser.phone}</span>
                      </div>
                  </div>
                  
                  <ToastContainer position="top-right"
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={true}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />

              </div>     
    )
}

export default UserDetailHead;