import { render } from "@testing-library/react";
import React  from "react" ;
import {Route} from 'react-router-dom';

import Dashboard from '../page/dashboard/Dashboard';

import UserList from "../page/user/userlist/UserList";
import UserAdd from '../page/user/useradd/UserAdd'
import UserDetail from '../page/user/userdetail/UserDetail';
import UserEdit from '../page/user/useredit/UserEdit';
import UserArtistEdit from '../page/user/userartistedit/UserArtistEdit';

import RoleAdd from '../page/role/roleadd/RoleAdd';
import RoleList from '../page/role/rolelist/RoleList'

import AlbumList from '../page/album/albumlist/AlbumList';
import AlbumAdd from '../page/album/albumadd/AlbumAdd';
import AlbumDetail from '../page/album/albumdetail/AlbumDetail' ;
import AlbumEdit from '../page/album/albumedit/AlbumEdit';

import TrackDetail from '../page/track/trackdetail/TrackDetail';
import TrackList from '../page/track/tracklist/TrackList'
import TrackAdd from '../page/track/trackadd/TrackAdd';
import TrackEdit from '../page/track/trackedit/TrackEdit'

import MusicVideoAdd from '../page/musicvideo/musicvideoadd/MusicVideoAdd'
import MusicVideoList from '../page/musicvideo/musicvideolist/MusicVideoList'
import MusicVideoDetail from '../page/musicvideo/musicvideodetail/MusicVideoDetail';
import MusicVideoEdit from '../page/musicvideo/musicvideoedit/MusicVideoEdit';

import PodcastsAdd from '../page/podcasts/podcastsadd/PodcastsAdd';
import PodcastList from "../page/podcasts/podcastlist/PodcastList";
import PodcastDetail from "../page/podcasts/podcastdetail/PodcastDetail";
import PodcastEdit from '../page/podcasts/podcastedit/PodcastEdit';

import Followers from '../page/follower/Followers';


const RouteData = [
    {
        'path':"/panel" ,
        'component':Dashboard ,
    } ,
    {
        'path':"/panel/userlist" ,
        'component':UserList ,
    } ,
    {
        'path':"/panel/useradd" ,
        'component':UserAdd ,
    } ,
    {
        'path':"/panel/user/:id" ,
        'component':UserDetail ,
    } ,
    {
        'path':"/panel/useredit/:id" ,
        'component':UserEdit ,
    } ,
    {
        'path':"/panel/userartistedit" ,
        'component':UserArtistEdit ,
    } ,
    {
        'path':"/panel/roleadd" ,
        'component':RoleAdd ,
    } ,
    {
        'path':"/panel/rolelist" ,
        'component':RoleList ,
    } ,
    {
        'path':"/panel/albumlist" ,
        'component':AlbumList ,
    } ,
    {
        'path':"/panel/albumadd" ,
        'component':AlbumAdd ,
    } ,
    {
        'path':"/panel/albumdetail/:id" ,
        'component':AlbumDetail ,
    } ,
    {
        'path':"/panel/albumedit/:id" ,
        'component':AlbumEdit ,
    } ,
    {
        'path':"/panel/trackdetail/:albumId/:trackId" ,
        'component':TrackDetail ,
    } ,
    {
        'path':"/panel/tracklist" ,
        'component':TrackList ,
    } ,
    {
        'path':"/panel/trackAdd/:id" ,
        'component':TrackAdd ,
    } ,
    {
        'path':"/panel/trackedit/:id" ,
        'component':TrackEdit ,
    } ,
    {
        'path':"/panel/musicvideoadd" ,
        'component':MusicVideoAdd ,
    } ,
    {
        'path':"/panel/musicvideolist" ,
        'component':MusicVideoList ,
    } ,
    {
        'path':"/panel/musicvideodetail/:id" ,
        'component':MusicVideoDetail ,
    } ,
    {
        'path':"/panel/musicvideoedit/:id" ,
        'component':MusicVideoEdit ,
    } ,
    {
        'path':"/panel/podcastadd" ,
        'component':PodcastsAdd ,
    } ,
    {
        'path':"/panel/podcastlist" ,
        'component':PodcastList ,
    } ,
    {
        'path':"/panel/podcastdetail/:id" ,
        'component':PodcastDetail ,
    } ,
    {
        'path':"/panel/podcastedit/:id" ,
        'component':PodcastEdit ,
    },
    {
        'path':"/panel/followers" ,
        'component':Followers ,
    },
  
]

export default RouteData ;

