import React, { useEffect, useRef, useState } from "react" ;
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faEllipsisV , faChevronLeft , faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {Get_All_Track} from '../../../widget/API';
import { useLocation } from 'react-router-dom'
import { cssTransition } from "react-toastify";
import {Link} from 'react-router-dom';
import TablePage from "../../../widget/Table/TablePage";
import { Select , DatePicker, Form , Input ,InputNumber} from 'antd';





function TrackList(props) {
    const[dispaly , setDisplay]=useState(false);
    const[listTrack , setListTrack]=useState([]);
    const [currentPage , setCurrentPage]=useState(1);
    const [lastPage , setLastPage]=useState(0);
    const [sort , setSort]=useState(null);
    const {Option} = Select ;


    const changePage=(status)=>{
        if(status){
            if(lastPage >= currentPage + 1){
                getData(currentPage + 1 , sort)
            }
        }
        else {
            if(currentPage > 1){
                getData(currentPage - 1 , sort)
            }
        }
    }


    const filterList=async(value)=>{
        await setSort(value)
        getData(currentPage , value)
    }


    const col = [
        {
            title:"name" ,
            sort:'name',
            render:(i) => (<Link to={`/panel/trackdetail/${i.album_id}/${i.id}`}>{i.name != null && i.name}</Link>)
        },
        {
            title:"Like" ,
            render:(i) => (<>{i.likes_count != null && i.likes_count}</>)
        },
        {
            title:"Comment" ,
            render:(i) => (<>{i.comments_count != null && i.comments_count}</>)
        },
        {
            title:"View" ,
            render:(i) => (<>{i.views_count	 != null && i.views_count}</>)
        },
        {
            title:"Album" ,
            render:(i) => (<>{i.album != null && i.album.name}</>)
        },
    ]


    const getData=(current , sort)=>{
        const returndata =  Get_All_Track(current , sort);
        returndata.then((res)=>{
        if(res.result == 'ok'){
            setListTrack(res.data.data)
            setCurrentPage(res.data.current_page)
            setLastPage(res.data.last_page)
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            Toast_noty('please try again', 5000, "error");
        })
    }


    useEffect(()=>{
        getData(currentPage)
      },[])

    return (
        <>
        <div className="search-list">
            <div className="search-list-head"><span>Search</span></div>
            <div className="search-list-main">
                <div className="search-list-main-item" >
                    <span>Sort by</span>
                    <Select
                        allowClear
                        onChange={(value)=>filterList(value)}
                        >
                            <Option value={"name"}>{"name"}</Option>
                            <Option value={"duration"}>{"duration"}</Option>
                            <Option value={"likes_count"}>{"likes"}</Option>
                            <Option value={"comments_count"}>{"comments"}</Option>
                            <Option value={"views_count"}>{"views"}</Option>
                    </Select>
                </div>
            </div>
        </div>
        <div className="fullPage">
            <div className="pagelist">
                <div className="pagelist-head">
                    <span>Track</span>
                </div>
                <TablePage columns={col} data={listTrack}>
                    {currentPage != 1 && 
                        <div className="table-counter-main-item" onClick={()=>changePage(false)} ><FontAwesomeIcon icon={faChevronLeft} /></div>
                    }
                        <div className="table-counter-main-item">{currentPage} </div>
                    {lastPage >= currentPage + 1 && 
                        <div className="table-counter-main-item" onClick={()=>changePage(true)} ><FontAwesomeIcon icon={faChevronRight} /></div>
                    }
                </TablePage>
            </div>
        </div> 
        </>
    )
}

export default TrackList;