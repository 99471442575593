import React, { useEffect, useRef, useState } from "react" ;
import Toast_noty from '../../../widget/Toast_noty'
import Loader from "../../../widget/Loader/Loader";
import { Select , DatePicker, Space , InputNumber , Form , Input} from 'antd';
import { faEdit, faFileImage, faHeart, faMailBulk, faMoneyBill, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Get_Music_Video_Detail  , Edit_Music_Video} from '../../../widget/API';
import MusicVideoAutoFields from '../MusicVideoAutoFields'

import '../musicvideoStyle.css';




function MusicVideoEdit(props) {
    const[dispaly , setDisplay]=useState(false);
    const [musicVideo , setMusicVideo]=useState(null);
    const [listArtists , setListArtists]=useState([])
    // const[feats , setFeats]=useState(false);
    const[pay , setPay]=useState(false);

    const { Option } = Select;
    const [form] = Form.useForm();

    const onFinishEdit=(values)=>{
        if(values && values.labels){
            values['labels'] =  Object.entries(values.labels).map(([key , value])=>{
                if(value && value._d){
                    return {
                        "id" : key,
                        "value" : String(moment(value._d).format('YYYY-MM-DD hh:mm')),
                    }
                }
                else {
                    return {
                        "id" : key,
                        "value" : String(value),
                    }
                }
                
            })
        }
        values.release_time = moment(values.release_time._d).format('YYYY-MM-DD hh:mm')
        setDisplay(true);
        const returndata =  Edit_Music_Video(values , props.match.params.id , pay);
        returndata.then((res)=>{
        setDisplay(false);
        if(res.result == 'ok'){
            props.history.push(`/panel/musicvideodetail/${props.match.params.id}`);  
        }
        else if(res.error == 'unauthenticated') {
            localStorage.clear();
            props.history.push('/');  
        }
        else {
            Toast_noty(res.error, 5000, "error");
        }
        })
         returndata.catch((er)=>{
            setDisplay(false);
            Toast_noty('please try again', 5000, "error");
        })

    }




    const getDetailMusiVideo=()=>{
        if(props.match.params.id){
            setDisplay(true)
            const returndata =  Get_Music_Video_Detail(props.match.params.id);
            returndata.then((res)=>{
            setDisplay(false)
            if(res.result == 'ok'){
                setMusicVideo(res.data)
                if(res.data.product != null){
                    setPay(true);
                    form.setFieldsValue({'pay' : true})
                    form.setFieldsValue({'Rial_price' : res.data.product.price_rial})
                    form.setFieldsValue({'Dollar_price' : res.data.product.price_dollar})
                    form.setFieldsValue({'vip' : res.data.product.vip})

                }
                form.setFieldsValue({'name' : res.data.name})
                form.setFieldsValue({'director' : res.data.director})
                form.setFieldsValue({'release_time' : moment(res.data.release_time)})
                form.setFieldsValue({'gender_id' : res.data.gender_id})
                if(res.data.labels){
                    for(let i of res.data.labels){
                        if(i.label && i.label != null && i.label.property.type == 'enum' && i.label.property.choose == 'single'){
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : parseInt(i.value)}})
                        }
                        else if(i.label && i.label != null && i.label.property.type == 'enum' && i.label.property.choose == 'multi'){
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : 
                                i.value.split(",").map(item=>{
                                    return parseInt(item)
                                })
                            }})
                        }
                        else if (i.label && i.label != null && i.label.property.type == 'date_time') {
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : moment(i.value)}})
                        }
                        else if(i.label && i.label != null ) {
                            form.setFieldsValue({ 'labels' : {[i.label_value_id] : i.value}})
                        }
                    }
                }
            }
            else if(res.error == 'unauthenticated') {
                localStorage.clear();
                props.history.push('/');  
            }
            else {
                Toast_noty(res.error, 5000, "error");
            }
            })
             returndata.catch((er)=>{
                setDisplay(false)
                Toast_noty('please try again', 5000, "error");
            })
        }
    }

    useEffect(()=>{
        if(props.match.params.id){
            getDetailMusiVideo()
        }
    },[props.match.params.id])





    return (
        <div className="musicvideodetail">
            {dispaly && <Loader/>}
            <div className="addmusicvideo-cont">
                <Form form={form} onFinish={onFinishEdit} className="addmusicvideo-cont-form">
                    <div className="addmusicvideo-cont-form-section">


                        <div className="addmusicvideo-cont-form-section-item">
                            <span>Music video name</span>
                            <Form.Item name="name" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <MusicVideoAutoFields/>

                        <div className="addmusicvideo-cont-form-section-item">
                            <span>Director</span>
                            <Form.Item name="director" rules={[{ required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>

                        <div className="addmusicvideo-cont-form-section-item">
                            <span>Release time</span>
                            <Form.Item name="release_time" rules={[{ required: true}]}>
                                <DatePicker />
                            </Form.Item>
                        </div>

                        {/* <div className="addmusicvideo-cont-form-section-item">
                            <span>Pay status</span>
                            <Form.Item name="pay" rules={[{ required: true}]}>
                                <Select
                                    allowClear
                                    onChange={(value)=>{setPay(value)}}
                                    >
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </div> */}

                        {/* {pay == true && 
                        <div className="addmusicvideo-cont-form-section-item">
                            <span>Rial price</span>
                            <Form.Item name="Rial_price" rules={[{ required: true}]}>
                                <InputNumber />
                            </Form.Item>
                        </div>
                        } */}

                        {/* {pay == true && 
                        <div className="addmusicvideo-cont-form-section-item">
                            <span>Dollar price</span>
                            <Form.Item name="Dollar_price" rules={[{ required: true}]}>
                                <InputNumber />
                            </Form.Item>
                        </div>
                        } */}

                        {/* {pay == true && 
                        <div className="addmusicvideo-cont-form-section-item">
                            <span>Vip</span>
                            <Form.Item name="vip" rules={[{ required: true}]}>
                                <Select
                                    allowClear
                                    >
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </div>
                        } */}

                    </div>
                    <div className="addmusicvideo-cont-form-footer"><button className="addmusicvideo-cont-form-footer-btn-next">Edit</button></div>
                </Form>
            </div>
        </div>
    )
}

export default MusicVideoEdit;